<app-model *ngIf="typeCatalogue=='AUTO'" class="modal fade bd-example-modal-lg" tabindex="-1"
           role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
           id="auto_{{productSupplier.supplier.label}}_{{productSupplier.code}}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title" id="auto_exampleModalLongTitle">
                    <div class="section-title mb-10">
                        <h2 class="float-left">{{productSupplier.supplier.label}} </h2>
                        <h6 style="padding: 5px;text-decoration: underline;color: #488fd8;" class="float-right"><a
                                target="_blank" href="{{productSupplier.url}}" class="default-color">voir fiche produit
                            externe du fournisseur</a></h6>
                    </div>
                </div>
                <img style="margin-right: 42px;" class="flag-deliveryCountry"
                     src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">
                <button type="submit" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('auto_'+productSupplier.supplier.label +'_'+productSupplier.code)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #f="ngForm" *ngIf="!showFormSizes" id="nauto_productSupplierAddForm" name="productSupplierAddForm"
                  (ngSubmit)="f.form.valid && onSubmit(f)">
            <div class="modal-body">
                <div class="tab nav-bt">
                    <ngb-tabset #productDetails class="tab nav-center">
                        <ngb-tab id="infosProduct" title="Infos produit fournisseur">
                            <ng-template ngbTabContent>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label class="control-label" for="auto_productSupplierCode">Code*</label>
                                        <div class="mb-2">
                                            <input #productSupplierCode="ngModel" id="auto_productSupplierCode"
                                                   [(ngModel)]="productSupplier.code" disabled type="text" required
                                                   class="form-control" name="productSupplierCode"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label class="control-label" for="auto_stock">Stock*</label>
                                        <div class="mb-2">
                                            <input #stock="ngModel" id="auto_stock" [(ngModel)]="productSupplier.stock"
                                                   disabled type="text" required class="form-control" name="stock"/>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <label class="control-label" for="auto_ean">Ean*</label>
                                        <div class="mb-2">
                                            <input #ean="ngModel" id="auto_ean" [(ngModel)]="productYoukado.ean"
                                                   disabled type="text" required class="form-control" name="ean"/>
                                        </div>
                                    </div>
                                    <div *ngIf="productSupplier.supplier.code == 'FRS729' || productSupplier.supplier.code == 'FRS644' || productSupplier.supplier.code == 'FRS643'|| productSupplier.supplier.code  == 'FRS631'" class="col-md-4 mb-3">
                                        <label class="control-label"
                                               for="nc8">Code douanier NC8*</label>
                                        <div class="mb-2">
                                            <input required type="text" class="form-control"
                                                   id="nc8" name="nc8"
                                                   [(ngModel)]="productSupplier.nc8"
                                                   #nc8="ngModel"/>
                                            <em class="error help-block"
                                                *ngIf="f.invalid && nc8.errors?.required">
                                                Merci de saisir un code nc8
                                            </em>
                                        </div>
                                    </div>
                                    <div *ngIf="productSupplier.supplier.code == 'FRS729'  || productSupplier.supplier.code == 'FRS644' || productSupplier.supplier.code == 'FRS643'|| productSupplier.supplier.code  == 'FRS631'" class="col-md-4 mb-3">
                                        <label class="control-label" for="weight">Poids nette produit kg*</label>
                                        <div class="mb-2">
                                            <input required type="number" class="form-control"
                                                   id="weight" name="weight"
                                                   [(ngModel)]="productSupplier.weight"
                                                   #weight="ngModel"/>
                                            <em class="error help-block"
                                                *ngIf="f.invalid && weight.errors?.required">
                                                Merci de saisir un poids
                                            </em>
                                        </div>
                                    </div>

                                </div>
                                <div class="card card-statistics">
                                    <div class="card-body">
                                        <div class="main-content">
                                            <div class="tab round shadow">
                                                <ngb-tabset style="text-align: left;" class="nav nav-tabs">
                                                    <ngb-tab
                                                            *ngFor="let translation of productSupplier.translationProductSupplier">
                                                        <ng-template ngbTabTitle>{{translation.language.code}}
                                                        </ng-template>
                                                        <ng-template ngbTabContent>
                                                            <div class="form-row">

                                                                <div class="col-md-4 mb-3">
                                                                    <label class="control-label"
                                                                           for="translationLabel">libellé* <b>({{translation.language.label}})</b></label>
                                                                    <div class="mb-2">
                                                                        <input #translationLabel="ngModel"
                                                                               id="translationLabel"
                                                                               [(ngModel)]="translation.label" disabled
                                                                               type="text" required class="form-control"
                                                                               name="translationLabel"/>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-8 mb-3">
                                                                    <label class="control-tags"
                                                                           for="tags">Mots-clés <b>({{translation.language.label}})</b></label>
                                                                    <div class="mb-2">
                                                                        <tag-input [disable]="true" id="tags"
                                                                                   [ripple]="false"
                                                                                   [theme]="'bootstrap'"
                                                                                   [ngModel]="translation.tags"
                                                                                   name="items"
                                                                                   name="tags" #tags="ngModel">
                                                                        </tag-input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label class="control-label"
                                                                   for="description">Description <b>({{translation.language.label}})</b></label>
                                                                   <textarea id="description" 
                                                                   name="description" 
                                                                   [(ngModel)]="translation.description" 
                                                                   class="form-control" 
                                                                   rows="5" 
                                                                   [readOnly]="true" disabled>
                                                         </textarea>
                                                        </ng-template>
                                                    </ngb-tab>
                                                </ngb-tabset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Prix fournisseur">
                            <ng-template ngbTabContent>
                                <div class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_dutyFreeBuyingPrice">Prix
                                                    d'achat HT</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_dutyFreeBuyingPrice" name="dutyFreeBuyingPrice"
                                                           [(ngModel)]="productSupplier.dutyFreeBuyingPrice"
                                                           #dutyFreeBuyingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_allTaxesIncludedBuyingPrice">Prix
                                                    d'achat TTC</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_allTaxesIncludedBuyingPrice"
                                                           name="allTaxesIncludedBuyingPrice"
                                                           [(ngModel)]="productSupplier.allTaxesIncludedBuyingPrice"
                                                           #allTaxesIncludedBuyingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label">Taux TVA*</label>
                                                <div class="mb-2">
                                                    <select
                                                            *ngFor="let productSupplierPrice of productSupplier?.productSupplierPrices; let index = index;"
                                                            [compareWith]="compareFn" name="vat"
                                                            [(ngModel)]="productSupplierPrice.vat" #vat="ngModel"
                                                            disabled
                                                            class="custom-select custom-select-lg mb-3">
                                                        <option *ngFor="let vat of vats" [ngValue]="vat" selected>
                                                            {{vat.rate}}</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_dutyFreeShippingPrice">Frais de
                                                    port HT</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_dutyFreeShippingPrice" name="dutyFreeShippingPrice"
                                                           [(ngModel)]="productSupplier.dutyFreeShippingPrice"
                                                           #dutyFreeShippingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label"
                                                       for="auto_allTaxesIncludedShippingPrice">Frais
                                                    de port TTC</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_allTaxesIncludedShippingPrice"
                                                           name="allTaxesIncludedShippingPrice"
                                                           [(ngModel)]="productSupplier.allTaxesIncludedShippingPrice"
                                                           #allTaxesIncludedShippingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_dutyFreeBuyingPrice">Montant
                                                    TVA*</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_vatAmount" name="auto_vatAmount"
                                                           [(ngModel)]="productSupplier.vatAmount"
                                                           #dutyFreeBuyingPrice="ngModel"/>
                                                </div>
                                            </div>

                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_ecoTaxes">Ecos taxes</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_ecoTaxes" name="ecoTaxes"
                                                           [(ngModel)]="productSupplier.ecoTaxes" #ecoTaxes="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_d3e">D3e</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_d3e" name="d3e" [(ngModel)]="productSupplier.d3e"
                                                           #d3e="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_sorecop">Sorecop</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_sorecop" name="sorecop"
                                                           [(ngModel)]="productSupplier.sorecop" #sorecop="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_dutyFreeTotalBuyingPrice">Prix
                                                    de revient HT</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_dutyFreeTotalBuyingPrice"
                                                           name="dutyFreeTotalBuyingPrice"
                                                           [(ngModel)]="productSupplier.dutyFreeTotalBuyingPrice"
                                                           #dutyFreeTotalBuyingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label"
                                                       for="auto_allTaxesIncludedTotalBuyingPrice">Prix
                                                    de revient TTC</label>
                                                <div class="mb-2">
                                                    <input disabled type="number" required class="form-control"
                                                           id="auto_allTaxesIncludedTotalBuyingPrice"
                                                           name="allTaxesIncludedTotalBuyingPrice"
                                                           [(ngModel)]="productSupplier.allTaxesIncludedTotalBuyingPrice"
                                                           #allTaxesIncludedTotalBuyingPrice="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label">Marge</label>
                                                <div class="mb-2">
                                                    <input type="number" required disabled class="form-control"
                                                           id="auto_margin" name="auto_margin"
                                                           [(ngModel)]="productSupplier.margin" #margin="ngModel"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label" for="auto_allTaxesIncludedPublicPrice">Prix
                                                    public fournisseur</label>
                                                <div class="mb-2">
                                                    <input disabled type="text" required class="form-control"
                                                           id="auto_allTaxesIncludedPublicPrice"
                                                           name="allTaxesIncludedPublicPrice"
                                                           [(ngModel)]="productSupplier.allTaxesIncludedPublicPrice"
                                                           #allTaxesIncludedPublicPrice="ngModel"/>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Tailles" *ngIf="sizes != null && sizes.length != 0">
                            <ng-template ngbTabContent>
                                <div class="card card-statistics h-100">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <ngx-datatable #mydatatable class="bootstrap" [headerHeight]="50"
                                                           [limit]="5" [columnMode]="'force'" [footerHeight]="50"
                                                           [rowHeight]="'auto'" [rows]="sizes">
                                                <ngx-datatable-column name="code">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Code</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex"
                                                                 let-value="value" let-row="row">
                                                        <span title="Double click to edit"
                                                              (dblclick)="editing[rowIndex + '-name'] = true"
                                                              *ngIf="!editing[rowIndex + '-name']">
                                                            {{value}}
                                                        </span>
                                                        <input autofocus (blur)="updateValue($event, 'name', rowIndex)"
                                                               *ngIf="editing[rowIndex+ '-name']" type="text"
                                                               [value]="value"/>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="size">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Taille</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex"
                                                                 let-row="row" let-value="value">
                                                        <span title="Double click to edit"
                                                              (dblclick)="editing[rowIndex + '-gender'] = true"
                                                              *ngIf="!editing[rowIndex + '-gender']">
                                                            {{value}}
                                                        </span>
                                                        <select class="selectpicker"
                                                                *ngIf="editing[rowIndex + '-gender']"
                                                                (change)="updateValue($event, 'gender', rowIndex)"
                                                                [value]="value">
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="stock">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Stock</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="dutyFreeBuyingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Prix HT</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="allTaxesIncludedBuyingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Prix TTC</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="vat">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Taux TVA</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                                 let-row="row">
                                                        <span
                                                                *ngFor="let productSupplierPrice of row?.productSupplierPrices; let index = index;">
                                                            {{productSupplierPrice?.vat?.rate}}
                                                        </span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="dutyFreeShippingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>FP HT</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="allTaxesIncludedShippingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>FP TTC</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="vatAmount">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Montant TVA</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="dutyFreeTotalBuyingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>PR HT</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="allTaxesIncludedTotalBuyingPrice">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>PR TTC</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="state">
                                                    <ng-template let-column="column" ngx-datatable-header-template>
                                                        <span>Statut</span>
                                                    </ng-template>
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                                 let-rowIndex="rowIndex" let-row="row">
                                                        <span *ngIf="!row.stateHidden" [ngClass]="{
                                                        'badge-danger': value==='declined',
                                                        'badge-warning': value==='unpublished',
                                                        'badge-primary': value==='standby',
                                                        'badge-success': value==='published',
                                                        'badge-info': value==='selected',
                                                        'badge-secondary':value==='new'}" class="badge badge_taille">
                                                            {{'core.status.' + value | translate}}
                                                        </span>
                                                        <select *ngIf="row.stateHidden" [value]="row.state"
                                                                (change)="updateStateRow(row,$event)" id="size_state"
                                                                name="size_state"
                                                                class="custom-select custom-select-lg mb-3">
                                                            <option value="published">
                                                                Publié
                                                            </option>
                                                            <option value="unpublished">
                                                                Dépublié
                                                            </option>
                                                            <option value="standby">
                                                                Stand-by
                                                            </option>
                                                        </select>
                                                        <i style="padding-left:2px;cursor: pointer;"
                                                           *ngIf="!row.stateHidden"
                                                           (click)="showSelectionState(row,$event)"
                                                           class="fa fa-edit"></i>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column *ngIf="false" name="stateHidden">

                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('auto_'+productSupplier.supplier.label+'_'+productSupplier.code)">
                    Fermer
                </button>
                <button *ngIf="productSupplier.supplier.code == 'FRS729'  || productSupplier.supplier.code == 'FRS644' || productSupplier.supplier.code == 'FRS643'|| productSupplier.supplier.code  == 'FRS631'" type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="f.form.valid && updateProduct('auto_'+productSupplier.supplier.label+'_'+productSupplier.code)">Modifier
                    </button>
            </div>
        </form>
        </div>
    </div>
</app-model>

<app-model *ngIf="typeCatalogue != 'AUTO'" class="modal fade bd-example-modal-lg" tabindex="-1"
           role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
           id="nauto_{{productSupplier.supplier.label}}_{{productSupplier.code}}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title" id="nauto_exampleModalLongTitle">
                    <div class="section-title mb-10">
                        <h2 class="float-left">{{productSupplier.supplier.label}} </h2>
                    </div>
                </div>
                <button (click)="addSize()" style="position: absolute;right: 168px;"
                        class="btn btn-secondary button_space">Ajouter
                    une taille
                </button>
                <img style="margin-right: 42px;" class="flag-deliveryCountry"
                     src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">
                <button type="submit" class="close" data-dismiss="modal" aria-label="Fermer" (click)="reset()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #f="ngForm" *ngIf="!showFormSizes" id="nauto_productSupplierAddForm" name="productSupplierAddForm"
                  (ngSubmit)="f.form.valid && onSubmit(f)">
                <div class="modal-body">
                    <div class="tab nav-bt">
                        <div class="card card-statistics mb-30">
                            <div class="card-body">
                                <div class="tab nav-bt">
                                    <ngb-tabset #tabs class="tab nav-center">
                                        <ngb-tab id="detailsProduct" title="Infos produit fournisseur">
                                            <ng-template ngbTabContent>

                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label"
                                                               for="productSupplierCode">Code*</label>
                                                        <div class="mb-2">
                                                            <input [disabled]="productSupplier.idProductSupplier != null" type="text" required class="form-control"
                                                                   id="productSupplierCode" name="productSupplierCode"
                                                                   [(ngModel)]="productSupplier.code"
                                                                   #productSupplierCode="ngModel"/>
                                                            <em class="error help-block"
                                                                *ngIf="f.invalid && productSupplierCode.errors?.required">
                                                                Merci de saisir un code
                                                            </em>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="stock">Stock*</label>
                                                        <div class="mb-2">
                                                            <input type="number" required class="form-control"
                                                                   id="stock" name="stock"
                                                                   [(ngModel)]="productSupplier.stock"
                                                                   #stock="ngModel"/>
                                                            <em class="error help-block"
                                                                *ngIf="f.invalid && stock.errors?.required">
                                                                Merci de saisir un stock
                                                            </em>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="auto_ean">Ean*</label>
                                                        <div class="mb-2">
                                                            <input #ean="ngModel" id="auto_ean" [(ngModel)]="productYoukado.ean"
                                                                   disabled type="text" required class="form-control" name="ean"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3"
                                                         *ngIf="otherDeliveryCountries.length > 0">
                                                        <label class="control-label" for="deliveryCountries">Autre pays
                                                            de
                                                            livraison</label>
                                                        <ng-multiselect-dropdown id="deliveryCountries"
                                                                                 name="deliveryCountries"
                                                                                 [settings]="dropdownSettingsDeliveryCountries"
                                                                                 [(ngModel)]="selectedDeliveryCountries"
                                                                                 [placeholder]="'Autres pays du fournisseur'"
                                                                                 [data]="otherDeliveryCountries">
                                                            <ng-template #optionsTemplate let-option="option">
                                                                <div>
                                                                    <img [src]="'assets/images/flags/' + option + '.png'"
                                                                         style="width: 18px; margin-right: 2px;"/>
                                                                    {{option}}
                                                                </div>
                                                            </ng-template>
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                    <div *ngIf="productSupplier.supplier.code == 'FRS729'  || productSupplier.supplier.code == 'FRS644' || productSupplier.supplier.code == 'FRS643'|| productSupplier.supplier.code  == 'FRS631'" class="col-md-4 mb-3">
                                                        <label class="control-label"
                                                               for="nc8">Code douanier NC8*</label>
                                                        <div class="mb-2">
                                                            <input required type="text" class="form-control"
                                                                   id="nc8" name="nc8"
                                                                   [(ngModel)]="productSupplier.nc8"
                                                                   #nc8="ngModel"/>
                                                            <em class="error help-block"
                                                                *ngIf="f.invalid && nc8.errors?.required">
                                                                Merci de saisir un code nc8
                                                            </em>
                                                           
                                                        </div>
                                                    </div>
                                                    <div *ngIf="productSupplier.supplier.code == 'FRS729' || productSupplier.supplier.code == 'FRS644' || productSupplier.supplier.code == 'FRS643'|| productSupplier.supplier.code  == 'FRS631'" class="col-md-4 mb-3">
                                                        <label class="control-label" for="weight">Poids nette produit kg*</label>
                                                        <div class="mb-2">
                                                            <input required type="number" class="form-control"
                                                                   id="weight" name="weight"
                                                                   [(ngModel)]="productSupplier.weight"
                                                                   #weight="ngModel"/>
                                                            <em class="error help-block"
                                                                *ngIf="f.invalid && weight.errors?.required">
                                                                Merci de saisir un poids
                                                            </em>
                                                        </div>
                                                    </div>
                                                   
                                                
                                                    <div class="col-md-12 mb-3">
                                                        <ngb-tabset #translationProductSupplier style="text-align: left;" class="tab round shadow">

                                                            <ng-container *ngFor="let translation of productSupplier.translationProductSupplier">
                                                                <ngb-tab id="{{translation.language.code}}">
                                                                    <ng-template ngbTabTitle>{{translation.language.code}}
                                                                    </ng-template>
                                                                    <ng-template ngbTabContent>
                                                                        <label class="control-label">Libellé fournisseur* <b>({{translation.language.label}})</b></label>
                                                                        <input #translationLabel="ngModel"
                                                                               id="translationLabel"
                                                                               [(ngModel)]="translation.label"
                                                                               (blur)="updateEmptyLibilleSupplierItemLg(translation.label)"
                                                                               type="text" required
                                                                               class="form-control"
                                                                               name="translationLabel"/>
                                                                    </ng-template>
                                                                </ngb-tab>
                                                            </ng-container>

                                                        </ngb-tabset>
                                                    </div>

                                                    <div class="col-md-12 mb-3">
                                                        <div class="card card-statistics mb-30">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Prix d'achat</h5>
                                                                <a (click)="addBuyingPrice()" href="javascript:void(0);"
                                                                   class="add-price" data-toggle="tooltip"
                                                                   data-placement="left" title=""
                                                                   ngbTooltip="Ajouter un prix d'achat">
                                                                    <i style="width: 40px;font-size: 26px;"
                                                                       class="fa fa-plus text-success action-button"></i>

                                                                </a>

                                                                <div *ngFor="let productSupplierPrice of productSupplier.productSupplierPrices; let index = index;"
                                                                     class="card card-statistics mb-30">
                                                                    <div class="card-body">
                                                                        <a *ngIf="!productSupplierPrice.idProductSupplierPrice"
                                                                           (click)="deleteBuyingPrice(index)"
                                                                           class="delete-price" data-toggle="tooltip"
                                                                           data-placement="left" title=""
                                                                           ngbTooltip="Supprimer le prix d'achat">
                                                                            <img style="width: 30px;"
                                                                                 src="assets/images/delete.png" alt="">
                                                                        </a>
                                                                        <div style="padding-top:10px" class="form-row">
                                                                            <div class="col-md-3 mb-3">
                                                                                <label class="control-label"
                                                                                       for="nauto_dutyFreeBuyingPrice_{{index}}">Prix
                                                                                    d'achat HT*</label>
                                                                                <div class="mb-2">
                                                                                    <input
                                                                                            (keyup)="calculBuyingPrices()"
                                                                                            type="number" required
                                                                                            class="form-control"
                                                                                            #dutyFreeBuyingPrice="ngModel"
                                                                                            id="nauto_dutyFreeBuyingPrice_{{index}}"
                                                                                            name="dutyFreeBuyingPrice_{{index}}"
                                                                                            [(ngModel)]="productSupplierPrice.dutyFreeBuyingPrice"/>
                                                                                    <em class="error help-block"
                                                                                        *ngIf="f.invalid && dutyFreeBuyingPrice.errors?.required">
                                                                                        Merci de saisir un Prix d'achat
                                                                                        HT
                                                                                    </em>
                                                                                    <em class="error help-block"
                                                                                        *ngIf="negativeMargin">
                                                                                        La marge est négative en
                                                                                    </em>
                                                                                    <em class="error help-block"
                                                                                        *ngFor="let countryName of negativeCountriesMargin">
                                                                                        {{countryName}}
                                                                                    </em>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3 mb-3">
                                                                                <label class="control-label"
                                                                                       for="nauto_allTaxesIncludedBuyingPrice">Prix
                                                                                    d'achat TTC*</label>
                                                                                <div class="mb-2">
                                                                                    <input type="number"
                                                                                           id="nauto_allTaxesIncludedBuyingPrice"
                                                                                           required disabled
                                                                                           class="form-control"
                                                                                           id="nauto_allTaxesIncludedBuyingPrice_{{index}}"
                                                                                           name="allTaxesIncludedBuyingPrice_{{index}}"
                                                                                           [(ngModel)]="productSupplierPrice.allTaxesIncludedBuyingPrice"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3 mb-3">
                                                                                <label class="control-label"
                                                                                       for="nauto_vat">Taux
                                                                                    TVA*</label>
                                                                                <div class="mb-2">
                                                                                    <select id="nauto_vat"
                                                                                            (change)="calculBuyingPrices()"
                                                                                            required
                                                                                            [compareWith]="compareFn"
                                                                                            (change)="onChangeVat()"
                                                                                            #vatToAdd="ngModel"
                                                                                            name="vat_{{index}}"
                                                                                            [(ngModel)]="productSupplierPrice.vat"
                                                                                            class="custom-select custom-select-lg mb-3">
                                                                                        <option *ngFor="let vat of vats"
                                                                                                [ngValue]="vat"
                                                                                                selected>
                                                                                            {{vat.rate}}
                                                                                        </option>
                                                                                    </select>
                                                                                    <em class="error help-block"
                                                                                        *ngIf="f.invalid && vatToAdd.errors?.required">
                                                                                        Merci de saisir un Taux TVA
                                                                                    </em>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3 mb-3">
                                                                                <label class="control-label"
                                                                                       for="nauto_vatAmount_{{index}}">
                                                                                    Montant TVA*
                                                                                </label>
                                                                                <div class="mb-2">
                                                                                    <input type="number" required
                                                                                           disabled class="form-control"
                                                                                           id="nauto_vatAmount_{{index}}"
                                                                                           name="vatAmount_{{index}}"
                                                                                           [(ngModel)]="productSupplierPrice.vatAmount"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="dutyFreeBuyingPriceToAdd">
                                                            Prix d'achat total HT*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="dutyFreeBuyingPriceToAdd"
                                                                   name="dutyFreeBuyingPriceToAdd"
                                                                   [(ngModel)]="productSupplier.dutyFreeBuyingPrice"
                                                                   #dutyFreeBuyingPriceToAdd="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label"
                                                               for="allTaxesIncludedBuyingPriceToAdd">
                                                            Prix d'achat total TTC*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="allTaxesIncludedBuyingPriceToAdd"
                                                                   name="allTaxesIncludedBuyingPriceToAdd"
                                                                   [(ngModel)]="productSupplier.allTaxesIncludedBuyingPrice"
                                                                   #allTaxesIncludedBuyingPriceToAdd="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="vatAmountToAdd">
                                                            Montant TVA total*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="vatAmountToAdd" name="vatAmountToAdd"
                                                                   [(ngModel)]="productSupplier.vatAmount"
                                                                   #vatAmountToAdd="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="dutyFreeShippingPrice">
                                                            Frais de port HT*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input (keyup)="calculAllTaxesIncludedShippingPrice()"
                                                                   type="number" required class="form-control"
                                                                   id="dutyFreeShippingPrice"
                                                                   name="dutyFreeShippingPrice"
                                                                   [(ngModel)]="productSupplier.dutyFreeShippingPrice"
                                                                   #dutyFreeShippingPrice="ngModel"/>
                                                            <em class="error help-block"
                                                                *ngIf="f.invalid && dutyFreeShippingPrice.errors?.required">
                                                                Merci de saisir les Frais de port HT
                                                            </em>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label"
                                                               for="allTaxesIncludedShippingPrice">
                                                            Frais de port TTC*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="allTaxesIncludedShippingPrice"
                                                                   name="allTaxesIncludedShippingPrice"
                                                                   [(ngModel)]="productSupplier.allTaxesIncludedShippingPrice"
                                                                   #allTaxesIncludedShippingPrice="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="allTaxesIncludedPublicPrice">
                                                            Prix public fournisseur
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" class="form-control"
                                                                   id="allTaxesIncludedPublicPrice"
                                                                   name="allTaxesIncludedPublicPrice"
                                                                   [(ngModel)]="productSupplier.allTaxesIncludedPublicPrice"
                                                                   #allTaxesIncludedPublicPrice="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mb-3">
                                                        <label class="control-label"
                                                               for="taux_taxes_tva">Taux
                                                            Taxes Tva*</label>
                                                        <div class="mb-2 col-md-4 pl-0">
                                                            <select id="taux_taxes_tva"
                                                                    (change)="calculAllTotalBuyingPrice(productSupplier)"
                                                                    name="taux_taxes_tv"
                                                                    class="custom-select custom-select-lg mb-3"
                                                                    [(ngModel)]="selectedTauxTaxesTvaValue">
                                                                <option *ngFor="let tauxTaxestva of allTauxTaxesTva" [ngValue]="tauxTaxestva.rate">
                                                                    {{tauxTaxestva.rate}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="ecoTaxes">Ecos taxes HT</label>
                                                        <div class="mb-2">
                                                            <input (keyup)="calculAllTotalBuyingPrice(productSupplier)"
                                                                   type="number"
                                                                   class="form-control" id="ecoTaxes" name="ecoTaxes"
                                                                   [(ngModel)]="productSupplier.ecoTaxes"
                                                                   #ecoTaxes="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="d3e">D3e HT</label>
                                                        <div class="mb-2">
                                                            <input (keyup)="calculAllTotalBuyingPrice(productSupplier)"
                                                                   type="number"
                                                                   class="form-control" id="d3e" name="d3e"
                                                                   [(ngModel)]="productSupplier.d3e" #d3e="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="sorecop">Sorecop HT</label>
                                                        <div class="mb-2">
                                                            <input (keyup)="calculAllTotalBuyingPrice(productSupplier)"
                                                                   type="number"
                                                                   class="form-control" id="sorecop" name="sorecop"
                                                                   [(ngModel)]="productSupplier.sorecop"
                                                                   #sorecop="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <!--TTC info -->
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="all_taxes_included_eco_taxes">Ecos taxes TTC</label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="all_taxes_included_eco_taxes"
                                                                   name="all_taxes_included_eco_taxes"
                                                                   [(ngModel)]="productSupplier.all_taxes_included_eco_taxes"
                                                                   #all_taxes_included_eco_taxes="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="all_taxes_included_d3e">D3e TTC</label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="all_taxes_included_d3e"
                                                                   name="all_taxes_included_d3e"
                                                                   [(ngModel)]="productSupplier.all_taxes_included_d3e"
                                                                   #all_taxes_included_d3e="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="all_taxes_included_sorecop">Sorecop TTC</label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="all_taxes_included_sorecop"
                                                                   name="all_taxes_included_sorecop"
                                                                   [(ngModel)]="productSupplier.all_taxes_included_sorecop"
                                                                   #all_taxes_included_sorecop="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label" for="dutyFreeTotalBuyingPrice">
                                                            Prix de revient HT*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="dutyFreeTotalBuyingPrice"
                                                                   name="dutyFreeTotalBuyingPrice"
                                                                   [(ngModel)]="productSupplier.dutyFreeTotalBuyingPrice"
                                                                   #dutyFreeTotalBuyingPrice="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label class="control-label"
                                                               for="allTaxesIncludedTotalBuyingPrice">
                                                            Prix de revient TTC*
                                                        </label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="allTaxesIncludedTotalBuyingPrice"
                                                                   name="allTaxesIncludedTotalBuyingPrice"
                                                                   [(ngModel)]="productSupplier.allTaxesIncludedTotalBuyingPrice"
                                                                   #allTaxesIncludedTotalBuyingPrice="ngModel"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <!--<label class="control-label" for="margin">Marge</label>-->
                                                        <label class="control-label">Marge</label>
                                                        <div class="mb-2">
                                                            <input type="number" required disabled class="form-control"
                                                                   id="margin" name="margin"
                                                                   [(ngModel)]="productSupplier.margin"
                                                                   #margin="ngModel"/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-template>
                                        </ngb-tab>
                                        <ngb-tab id="sizes"
                                                 *ngIf="productSupplier != null && productSupplier.childProductSuppliers != null && productSupplier.childProductSuppliers.length > 0"
                                                 title="Tailles">
                                            <ng-template ngbTabContent>

                                                <label *ngIf="dataMissing" style="color:red" class="control-label">
                                                    *Merci de remplir toutes les données obligatoires du tableau
                                                </label>
                                                <div class="card card-statistics h-100">
                                                    <div class="card-body">

                                                        <div class="table-responsive">
                                                            <ngx-datatable #sizesDatatable class="bootstrap"
                                                                           [headerHeight]="50" [limit]="10"
                                                                           [columnMode]="'force'"
                                                                           [footerHeight]="50" [rowHeight]="'auto'"
                                                                           [rows]="productSupplier.childProductSuppliers">

                                                                <ngx-datatable-row-detail [rowHeight]="570" #myDetailRow
                                                                                          (toggle)="onDetailToggle($event)">
                                                                    <ng-template let-rowIndex="rowIndex" let-row="row"
                                                                                 let-expanded="expanded"
                                                                                 ngx-datatable-row-detail-template>
                                                                        <div class="card card-statistics mb-30">
                                                                            <div class="card-body">
                                                                                <div class="form-row"
                                                                                     style="height: 88px;">
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label
                                                                                                class="control-label">Statut</label>
                                                                                        <div class="mb-2">
                                                                                            <select [value]="row.state"
                                                                                                    (change)="updateRow(row,$event)"
                                                                                                    id="size_state"
                                                                                                    name="size_state"
                                                                                                    class="custom-select custom-select-lg mb-3">
                                                                                                <option
                                                                                                        value="published">
                                                                                                    Publié
                                                                                                </option>
                                                                                                <option
                                                                                                        value="unpublished">
                                                                                                    Dépublié
                                                                                                </option>
                                                                                                <option value="standby">
                                                                                                    Stand-by
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-row">
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_code">Code*</label>
                                                                                        <div class="mb-2">
                                                                                            <input type="text" required
                                                                                                   class="form-control"
                                                                                                   name="size_code"
                                                                                                   [value]="row.code != null ? row.code : null"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_size">Taille*
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input type="text" required
                                                                                                   class="form-control"
                                                                                                   name="size_size"
                                                                                                   [value]="row.size != null ? row.size : null"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_stock">Stock*
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input type="text" required
                                                                                                   class="form-control"
                                                                                                   name="size_stock"
                                                                                                   [value]="row.stock != null ? row.stock : null"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_dutyFreeBuyingPrice">
                                                                                            Prix d'achat HT*
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   name="size_dutyFreeBuyingPrice"
                                                                                                   [value]="row.dutyFreeBuyingPrice"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_allTaxesIncludedBuyingPrice">
                                                                                            Prix d'achat TTC*
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input type="text" required
                                                                                                   disabled
                                                                                                   class="form-control"
                                                                                                   id="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                   name="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                   [value]="row.allTaxesIncludedBuyingPrice != null ? row.allTaxesIncludedBuyingPrice : null"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_allTaxesIncludedBuyingPrice">Taux
                                                                                            TVA*</label>
                                                                                        <div class="mb-2">
                                                                                            <input type="text" required
                                                                                                   disabled
                                                                                                   class="form-control"
                                                                                                   id="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                   name="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                   [value]="row.productSupplierPrices != null && row.productSupplierPrices.length != 0 ? row.productSupplierPrices[0]?.vat?.rate : 0"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_dutyFreeShippingPrice">
                                                                                            Frais de port HT
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input
                                                                                                   type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   id="size_dutyFreeShippingPrice"
                                                                                                   name="size_dutyFreeShippingPrice"
                                                                                                   [value]="row.dutyFreeShippingPrice"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_allTaxesIncludedShippingPrice">
                                                                                            Frais de port TTC
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   id="size_allTaxesIncludedShippingPrice"
                                                                                                   name="size_allTaxesIncludedShippingPrice"
                                                                                                   [value]="row.allTaxesIncludedShippingPrice"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_vatAmount">
                                                                                            Montant TVA*
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   id="size_vatAmount"
                                                                                                   name="size_vatAmount"
                                                                                                   [value]="row.vatAmount"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="siz_ecoTaxes">
                                                                                            Ecos taxes
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   class="form-control"
                                                                                                   id="siz_ecoTaxes"
                                                                                                   name="siz_ecoTaxes"
                                                                                                   [value]="row.ecoTaxes"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_d3e">
                                                                                            D3e
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   class="form-control"
                                                                                                   id="size_d3e"
                                                                                                   name="size_d3e"
                                                                                                   [value]="row.d3e"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_sorecop">Sorecop</label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   class="form-control"
                                                                                                   id="size_sorecop"
                                                                                                   name="size_sorecop"
                                                                                                   [value]="row.sorecop"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_dutyFreeTotalBuyingPrice">
                                                                                            Prix de revient HT
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   id="size_dutyFreeTotalBuyingPrice"
                                                                                                   name="size_dutyFreeTotalBuyingPrice"
                                                                                                   [value]="row.dutyFreeTotalBuyingPrice"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label"
                                                                                               for="size_allTaxesIncludedTotalBuyingPrice">
                                                                                            Prix de revient TTC
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input disabled
                                                                                                   type="number"
                                                                                                   required
                                                                                                   class="form-control"
                                                                                                   id="size_allTaxesIncludedTotalBuyingPrice"
                                                                                                   name="size_allTaxesIncludedTotalBuyingPrice"
                                                                                                   [value]="row.allTaxesIncludedTotalBuyingPrice"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4 mb-3">
                                                                                        <label class="control-label">
                                                                                            Marge
                                                                                        </label>
                                                                                        <div class="mb-2">
                                                                                            <input type="number"
                                                                                                   disabled
                                                                                                   class="form-control"
                                                                                                   id="size_margin"
                                                                                                   name="size_margin"
                                                                                                   [value]="row.margin"
                                                                                                   (keyup)="updateRow(row,$event)"/>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                </ngx-datatable-row-detail>

                                                                <ngx-datatable-column name="">

                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span (click)="toggleExpandRow(row)"
                                                                              style="color: #1212ef;text-decoration: underline;cursor: pointer;">Editer</span>
                                                                    </ng-template>

                                                                </ngx-datatable-column>

                                                                <ngx-datatable-column name="code">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Code</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>

                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="size">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Taille</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="stock">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Stock</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="dutyFreeBuyingPrice">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Prix HT</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="dutyFreeShippingPrice">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>FP HT</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>

                                                                <ngx-datatable-column name="vatAmount">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Montant TVA</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="dutyFreeTotalBuyingPrice">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>PR HT</span>
                                                                    </ng-template>
                                                                    <ng-template ngx-datatable-cell-template
                                                                                 let-rowIndex="rowIndex"
                                                                                 let-value="value"
                                                                                 let-row="row">
                                                                        <span>{{value}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="state">
                                                                    <ng-template let-column="column"
                                                                                 ngx-datatable-header-template>
                                                                        <span>Statut</span>
                                                                    </ng-template>
                                                                    <ng-template let-row="row"
                                                                                 ngx-datatable-cell-template
                                                                                 let-value="value">
                                                                        <span [ngClass]="{
                                                                        'badge-danger': value==='declined',
                                                                        'badge-warning': value==='unpublished',
                                                                        'badge-primary': value==='standby',
                                                                        'badge-success': value==='published',
                                                                        'badge-info': value==='selected',
                                                                        'badge-secondary':value==='new'}"
                                                                              class="badge badge_taille">
                                                                            {{'core.status.' + value | translate}}
                                                                        </span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                            </ngx-datatable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>

                                    </ngb-tabset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="reset()">Fermer
                    </button>
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="f.form.valid && updateProduct('nauto_'+productSupplier.supplier.label+'_'+productSupplier.code)">Modifier
                    </button>
                </div>
            </form>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="applyAllSizes">
    <form id="applyAllSizesForm" name="applyAllSizesForm">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <div class="section-title mb-10">
                            <h3 class="float-left"> Vous voulez appliquer ce changement sur les autre pays ?</h3>
                        </div>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                            (click)="closeModal('applyAllSizes')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="justify-content: center;">
                        <div class="mb-2">
                            <ng-multiselect-dropdown id="applyAllSizesCountries" name="deliveryCountries"
                                                     [settings]="dropdownSettingsDeliveryCountries"
                                                     [(ngModel)]="selectedDeliveryCountries"
                                                     [placeholder]="'Sélectionner pays'"
                                                     [data]="deliveryCountries">

                                <ng-template #optionsTemplate let-option="option">
                                    <div>
                                        <img [src]="'assets/images/flags/' + option + '.png'"
                                             style="width: 18px; margin-right: 2px;" alt="Drapeau_{{option}}"/>
                                        {{option}}
                                    </div>
                                </ng-template>
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="closeModal('applyAllSizes')">Fermer
                    </button>
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="onApplyAll()">Valider
                    </button>
                </div>

            </div>
        </div>
    </form>
</app-model>

<div [ngClass]="{'card_active': productSupplier.forced}" class="card card-statistics h-100 card_supplier">
    <a *ngIf="!productSupplier.idProductSupplier" (click)="deleteProductSupplier(indexProductSupplier)"
       class="delete-supplier" data-toggle="tooltip" data-placement="left" title=""
       ngbTooltip="Supprimer le fournisseur">
        <img style="width: 30px;" src="assets/images/delete.png">
    </a>
    <div style="margin-left: 10px;cursor:pointer" class="card-body">
        <input class="form-check-input float-left" type="radio" name="productSupplier"
               id="{{productSupplier.supplier.label}}" (click)="forceSupplier()" [checked]="productSupplier.forced">
        <a (click)="openModal((typeCatalogue=='AUTO' ? 'auto_' : 'nauto_')+productSupplier.supplier.label+'_'+productSupplier.code)"
           href="javascript:void(0);" class="text-dark float-right" data-toggle="tooltip" data-placement="left"
           title="" ngbTooltip="{{typeCatalogue=='AUTO' ? 'Voir détails':'Editer'}}"><i
                class="fa {{typeCatalogue=='AUTO' ? 'fa-eye':'fa-edit'}}"></i></a>
        <h5 (click)="forceSupplier()" class="card-title">{{productSupplier.supplier.label}}</h5>
        <ul (click)="forceSupplier()" class="addresss-info invoice-addresss list-unstyled">
            <li>
                <b>Code : </b><span>{{productSupplier.code}}</span>
            </li>
            <li><b>Prix d'achat HT : </b><span>{{productSupplier.dutyFreeBuyingPrice}}€</span></li>
            <li><b>Frais de port HT : </b><span>{{productSupplier.dutyFreeShippingPrice}}€</span></li>
            <li><b>Prix de revient HT : </b><span>{{productSupplier.dutyFreeTotalBuyingPrice}}€</span></li>
            <li><i ngbTooltip="{{productSupplier.margin < 0 ? 'Marge est négative' : 'Marge positive'}}"
                   class="fa fa-circle {{productSupplier.margin < 0  ? 'text-danger' : 'text-success'}} "></i><b style="margin-left: 5px">Marge : </b><span>{{productSupplier.margin}}%</span></li>
            <li><i ngbTooltip="{{productSupplier.stock <= 0 ? 'Rupture de stock' : 'En stock'}}"
                   class="fa fa-circle {{productSupplier.stock <= 0  ? 'text-danger' : 'text-success'}} "></i><b style="margin-left: 5px">Stock : </b><span>{{productSupplier.stock}}</span></li>
            <li><b>Type : </b><span>{{typeCatalogue && typeCatalogue == 'MANU' ? 'Manuel' : 'Automatique'}}</span></li>
            <li>
                <i ngbTooltip="{{getOrderType(productSupplier.deliveryCountryProductSupplier.code) && getOrderType(productSupplier.deliveryCountryProductSupplier.code) == 'INACTIF' ? 'Désactivé' : 'Activé'}}"
                   class="fa fa-circle {{getOrderType(productSupplier.deliveryCountryProductSupplier.code) == 'INACTIF' ? 'text-danger' : 'text-success'}} "></i><span
                    style="margin-left: 5px">{{getOrderType(productSupplier.deliveryCountryProductSupplier.code) && getOrderType(productSupplier.deliveryCountryProductSupplier.code) == 'INACTIF' ? 'Désactivé' : 'Activé'}}</span>
            </li>
            <b *ngIf="deliveryCountriesForSupplier != null && deliveryCountriesForSupplier.length > 0">Autre pays de
                livraison : </b>
            <u style="display:flex;">
                <li style="padding-left: 5px;text-align: center;"
                    *ngFor="let deliveryCountriyCode of deliveryCountriesForSupplier; let index = index;">
                    <img style="width: 30px; margin-right: 2px;height:20px;cursor:pointer"
                         src="assets/images/countries/{{deliveryCountriyCode}}.jpg">
                    <br>
                    <span><i
                            ngbTooltip="{{getOrderType(deliveryCountriyCode) && getOrderType(deliveryCountriyCode) == 'INACTIF' ? 'Désactivé' : 'Activé'}}"
                            class="fa fa-circle {{getOrderType(deliveryCountriyCode) == 'INACTIF' ? 'text-danger' : 'text-success'}} "></i><b
                            style="margin-left: 5px;">{{deliveryCountriyCode}}</b></span>
                </li>
            </u>
        </ul>
    </div>
</div>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="negativeMarginAlert">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title" id="exampleModalLongTitle">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert marge negative</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="redirectNegativeMargin()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;display: block;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Attention : La marge est negative pour les pays :
                        <span *ngFor="let productSupplierCantUpdate of productSupplierCantUpdate; let index = index;">
                            <b>{{productSupplierCantUpdate.deliveryCountryProductSupplier.label}}</b>
                            , fournisseur : <b>{{productSupplierCantUpdate.supplier.label}}</b>
                        </span>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="redirectNegativeMargin()">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>