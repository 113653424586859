import {Component, OnInit, Input, EventEmitter, Output, ViewChild, Injector} from '@angular/core';
import {ModalService,} from 'src/app/shared/services';
import {SupplierService} from 'src/app/shared/services/supplier.service';
import {
    VatDtoInterface,
    ProductSupplierPriceDtoInterface,
    CatalogueDtoInterface,
    ProductSupplierDtoInterface,
    ProductYoukadoDtoInterface,
    TranslationProductSupplierDtoInterface,
    SupplierToDeliveryCountryDtoInterface, LanguageDtoInterface, DeliveryCountryDtoInterface, TauxTaxesTvaDtoInterface
} from 'src/app/shared/interfaces/models/rest';
import {NgForm} from '@angular/forms';
import {VatsService} from "src/app/shared/services/vats-service";
import {ProductUtils} from "src/app/shared/utils/product.utils";
import {NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {CommonDataService} from "src/app/common-data.service";
import {DeliverycountryService} from "src/app/shared/services/deliverycountry.service";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {LanguageService} from "src/app/shared/services/language.service";

declare var require: any;
const data: any = require('../../../../../assets/json/datatable.json');

@Component({
    selector: 'app-products-supplier-item',
    templateUrl: './products-supplier-item.component.html',
    styleUrls: ['./products-supplier-item.component.css']
})
export class ProductsSupplierItemComponent extends BaseComponents implements OnInit {
    @ViewChild('tabs')
    private tabs: NgbTabset;
    @ViewChild('sizesDatatable')
    private table: any;

    @ViewChild('translationProductSupplier')
    private translationProductSupplier: NgbTabset;

    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() productSupplier: ProductSupplierDtoInterface;
    @Input() indexProductSupplier: number;
    @Input() deliveryCountry: any;
    @Input() productSuppliersCopy: ProductSupplierDtoInterface[];
    @Input() typeCatalogue: string;
    @Output() forceSupplierEvent = new EventEmitter<number>();
    @Output() bestProductSupplierOutput = new EventEmitter<ProductSupplierDtoInterface>();

    vats: VatDtoInterface[];
    vatShippingCosts: VatDtoInterface;
    productSupplierCopy: ProductSupplierDtoInterface[] = [];
    productSupplierCantUpdate: ProductSupplierDtoInterface[] = [];
    productSupplierSize: ProductSupplierDtoInterface;
    showPopupMarginNegatif: Boolean = false;
    deliveryCountriesForSupplier: string[];
    availableLanguages: LanguageDtoInterface[] = [];
    showPopup = false;
    allDeliveryCountries: DeliveryCountryDtoInterface[];
    deliveryCountries: DeliveryCountryDtoInterface[];


    sizes: any = [];
    filterRows: any = [];
    selectRows: any = [];
    sortRows: any = [];
    temp: any = [];
    editing: any = {};

    selected: any[] = [];
    // DataTable Content Titles
    columns: any = [
        {prop: 'Code'},
        {name: 'Taille'},
        {name: 'Prix HT'}
    ];

    allTauxTaxesTva: TauxTaxesTvaDtoInterface[] = [
        {idTauxTaxesTva: 0, rate: 0},
        {idTauxTaxesTva: 1, rate: 2.1},
        {idTauxTaxesTva: 2, rate: 4},
        {idTauxTaxesTva: 3, rate: 5.5},
        {idTauxTaxesTva: 4, rate: 6},
        {idTauxTaxesTva: 5, rate: 7},
        {idTauxTaxesTva: 6, rate: 10},
        {idTauxTaxesTva: 7, rate: 12},
        {idTauxTaxesTva: 8, rate: 13},
        {idTauxTaxesTva: 9, rate: 18},
        {idTauxTaxesTva: 10, rate: 19},
        {idTauxTaxesTva: 11, rate: 20},
        {idTauxTaxesTva: 12, rate: 21},
        {idTauxTaxesTva: 13, rate: 22},
        {idTauxTaxesTva: 14, rate: 22.16},
        {idTauxTaxesTva: 15, rate: 22.4},
        {idTauxTaxesTva: 16, rate: 23}
    ];
    selectedTauxTaxesTvaValue: number;
    disableVat: boolean = false;
    showButtonSizes: boolean = false;
    showFormSizes: boolean = false;
    alertError: boolean = false;
    dataMissing: boolean = false;

    dropdownSettingsDeliveryCountries: any = {};
    otherDeliveryCountries: any[] = [];
    selectedDeliveryCountries: any[] = [];

    constructor(
        private _commondata: CommonDataService,
        private deliveryCountryService: DeliverycountryService,
        private modalService: ModalService,
        private supplierService: SupplierService,
        protected vatsService: VatsService,
        protected languageService: LanguageService,
        protected injector: Injector) {
        super(injector);
        this.temp = [...data];
        this.filterRows = data;
        this.selectRows = data;
        this.sortRows = data;
    }

    ngOnInit(): void {
        this.deliveryCountryService.getDeliveryCountries().subscribe(res => {
            this.allDeliveryCountries = res;
        })
        this.selectedTauxTaxesTvaValue = this.allTauxTaxesTva[0].rate;


        this.sizes = this.productSupplier.childProductSuppliers;
        let catalog: CatalogueDtoInterface = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);
        this.showButtonSizes = this.sizes != null ? this.sizes.length > 0 || catalog.state == 'new' || catalog.state == 'selected' || catalog.state == 'declined' : false;
        this.productSupplierSize = {} as ProductSupplierDtoInterface;
        this.productSupplierSize.deliveryCountryProductSupplier = this.deliveryCountry;
        this.productSupplierSize.productSupplierPrices = [];
        this.productSupplierSize.productSupplierPrices.push({} as any);
        this.deliveryCountriesForSupplier = this.productYoukado.productSuppliers.filter(s => s.deliveryCountryProductSupplier.code != this.deliveryCountry.code && s.code == this.productSupplier.code && s.supplier.code == this.productSupplier.supplier.code).map((productSupplier: ProductSupplierDtoInterface) => productSupplier.deliveryCountryProductSupplier.code);
        this.deliveryCountriesForSupplier = this.deliveryCountriesForSupplier.filter(
            (element, i) => i === this.deliveryCountriesForSupplier.indexOf(element)
        );

        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: true,
            allowSearchFilter: false,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.availableLanguages = [];
        this.languageService.list().subscribe(
            (response) => {
                this.availableLanguages = response;
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        )
    }

    getLanguages() {
        if (this.productSupplier.translationProductSupplier == null || this.productSupplier.translationProductSupplier.length == 0) {
            this.productSupplier.translationProductSupplier = [];
        }

        const languagesProduct: string[] = this.productSupplier.translationProductSupplier.map((language: TranslationProductSupplierDtoInterface) => language.language.code);

        const languagesDeliveryCountry: LanguageDtoInterface[] = this.availableLanguages.map((language: LanguageDtoInterface) => language);

        for (let language of languagesDeliveryCountry) {

            if (!languagesProduct.includes(language.code)) {
                const translationProductSupplierDtoInterface = {} as TranslationProductSupplierDtoInterface;
                translationProductSupplierDtoInterface.language = language;
                this.productSupplier.translationProductSupplier.push(translationProductSupplierDtoInterface);
            }
        }

        this.productSupplier.translationProductSupplier.sort((a, b) => a.language.order < b.language.order ? -1 : 1);

    }

    getOrderType(deliveryCountriyCode: string): string {
        let supplierToDeliveryCountries: SupplierToDeliveryCountryDtoInterface = this.productSupplier.supplier.supplierToDeliveryCountries.find(s => s.deliveryCountriesSupplier.code == deliveryCountriyCode);
        return supplierToDeliveryCountries.orderType;
    }

    getOtherDeliveryCountries(): void {
        this.otherDeliveryCountries = [];
        this.selectedDeliveryCountries = [];

        let deliveryCountry = this.productYoukado.productSuppliers.filter(s => s.deliveryCountryProductSupplier.code != this.deliveryCountry.code && s.code == this.productSupplier.code && s.supplier.code == this.productSupplier.supplier.code).map((productSupplier: ProductSupplierDtoInterface) => productSupplier.deliveryCountryProductSupplier.code);
        this.otherDeliveryCountries = this.productSupplier.supplier.supplierToDeliveryCountries.filter(st => st.orderType != 'INACTIF').map((supplierToDeliveryCountries: SupplierToDeliveryCountryDtoInterface) => supplierToDeliveryCountries.deliveryCountriesSupplier);
        this.otherDeliveryCountries = this.otherDeliveryCountries.filter(d => d.code != this.deliveryCountry.code && !deliveryCountry.includes(d.code));

    }

    getSupplierDeliveryCountry(supplierId: number): SupplierToDeliveryCountryDtoInterface[] {
        this.commondataService.showLoader(true);
        this.supplierService.getSupplierDeliveryCountry(supplierId).subscribe(
            (response) => {
                this.commondataService.showLoader(false);
                return response;
                //console.log("SUPPLIERS : " + JSON.stringify(response));
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.commondataService.showLoader(false);
            }
        );
        return [];
    }

    addSupplierInSelectedCountries(): void {
        for (let deliveryCountry of this.selectedDeliveryCountries) {
            let productSupp = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryProductSupplier.code == deliveryCountry.code && ps.supplier.code == this.productSupplier.supplier.code && ps.code == this.productSupplier.code);
            if (productSupp == null) {
                let productSupplierToDuplicate: ProductSupplierDtoInterface = JSON.parse(JSON.stringify(this.productSupplier));

                if (deliveryCountry.code == 'BE') {
                    if (this.productSupplier.translationProductSupplier != null && this.productSupplier.translationProductSupplier != undefined && this.productSupplier.translationProductSupplier.length > 0) {
                        let translationProductSupplierFR = this.productSupplier.translationProductSupplier.find(t => t.language.code == 'FR');
                        let translationProductSupplierBE = this.productSupplier.translationProductSupplier.find(t => t.language.code == 'BE');

                        if (translationProductSupplierBE != null && !translationProductSupplierBE.label && translationProductSupplierFR != null
                            && translationProductSupplierFR.label) {

                            for (let trans of productSupplierToDuplicate.translationProductSupplier) {
                                if (trans.language.code == 'BE') {
                                    trans.label = translationProductSupplierFR.label;
                                }
                            }
                        }

                    }
                }

                if (deliveryCountry.code == 'FR') {
                    if (this.productSupplier.translationProductSupplier != null && this.productSupplier.translationProductSupplier != undefined && this.productSupplier.translationProductSupplier.length > 0) {
                        let translationProductSupplierFR = this.productSupplier.translationProductSupplier.find(t => t.language.code == 'FR');
                        let translationProductSupplierBE = this.productSupplier.translationProductSupplier.find(t => t.language.code == 'BE');

                        if (translationProductSupplierFR != null && !translationProductSupplierFR.label && translationProductSupplierBE != null
                            && translationProductSupplierBE.label) {

                            for (let trans of productSupplierToDuplicate.translationProductSupplier) {
                                if (trans.language.code == 'FR') {
                                    trans.label = translationProductSupplierBE.label;
                                }
                            }
                        }

                    }
                }


                productSupplierToDuplicate.idProductSupplier = null;
                productSupplierToDuplicate.deliveryCountryCode = this.productSupplier.deliveryCountryCode;
                productSupplierToDuplicate.deliveryCountryProductSupplier = this.otherDeliveryCountries.find(d => d.code == deliveryCountry.code);
                console.log("translationProductSupplier : " + JSON.stringify(productSupplierToDuplicate.translationProductSupplier));
                this.productYoukado.productSuppliers.push(productSupplierToDuplicate);
                this.addDeliveryCountry(productSupplierToDuplicate.deliveryCountryProductSupplier);
            }
        }
    }

    addDeliveryCountry(deliveryCountry: DeliveryCountryDtoInterface) {
        let catalogueCountry = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == deliveryCountry.code);
        if (catalogueCountry == null) {
            const catalogue = {} as CatalogueDtoInterface;
            catalogue.state = "new";
            catalogue.deliveryCountriesCatalogue = deliveryCountry;
            this.productYoukado.catalogues.push(catalogue);
        }
    }

    negativeMargin: boolean;
    negativeCountriesMargin: string[] = [];

    updateProduct(id:string) {

        this.productSupplierCantUpdate = [];
        for (let productSupplier of this.productYoukado.productSuppliers) {
            if (productSupplier.code == this.productSupplier.code) {
                if (productSupplier.margin < 0) {
                    //this.resetProductSupplier(productSupplier);
                    this.productSupplierCantUpdate.push(productSupplier);
                    this.showPopup = true;
                }
            }
        }

        if (!ProductUtils.isNullOrEmpty(this.productSupplier.childProductSuppliers)) {
            for (let childProductSupplier of this.productSupplier.childProductSuppliers) {
                if (ProductUtils.isNullOrEmpty(childProductSupplier.code) || ProductUtils.isNullOrEmpty(childProductSupplier.size) || ProductUtils.isNullOrEmpty(childProductSupplier.stock) || ProductUtils.isNullOrEmpty(childProductSupplier.dutyFreeBuyingPrice)) {
                    this.tabs.select("sizes");
                    this.dataMissing = true;
                    return false;
                }
            }
        }
        ProductUtils.sortProductSuppliers(this.productYoukado, this.deliveryCountry);
        this.addSupplierInSelectedCountries();
        this.closeModal(id);
        if (this.isDutyFreeBuyingPriceChange() || this.isDutyFreeShippingPriceChange()) {
            this.deliveryCountries = this.allDeliveryCountries
                .filter(d => this.productYoukado.productSuppliers
                    .map(e => e.deliveryCountryCode)
                    .filter(res => res !== this.deliveryCountry.code)
                    .includes(d.code));
            this.modalService.open('applyAllSizes')
        }

        if (this.showPopup) {
            return this.modalService.open("negativeMarginAlert") == undefined;
        }
        return true;
    }

    isDutyFreeShippingPriceChange(): boolean {
        let childProductSuppliers: ProductSupplierDtoInterface[] = this.productYoukado.productSuppliers.find(e => e.deliveryCountryCode === this.deliveryCountry.code)?.childProductSuppliers;
        if (childProductSuppliers) {
            for (let product of childProductSuppliers) {
                if (this.productSupplierCopy.find(e => e.deliveryCountryCode == this.deliveryCountry.code && e.supplier.code === this.productSupplier.supplier.code).childProductSuppliers
                    .findIndex(res => (res.code === product.code && res.dutyFreeShippingPrice !== product.dutyFreeShippingPrice)) !== -1) return true
            }
        }

        return false;
    }

    isDutyFreeBuyingPriceChange(): boolean {
        let childProductSuppliers: ProductSupplierDtoInterface[] = this.productYoukado.productSuppliers.find(e => e.deliveryCountryCode === this.deliveryCountry.code)?.childProductSuppliers;
        if (childProductSuppliers) {
            for (let product of childProductSuppliers) {
                if (this.productSupplierCopy.find(e => e.deliveryCountryCode == this.deliveryCountry.code && e.supplier.code === this.productSupplier.supplier.code).childProductSuppliers
                    .findIndex(res => (res.code === product.code && res.dutyFreeBuyingPrice !== product.dutyFreeBuyingPrice)) !== -1) return true
            }
        }

        return false;
    }

    onApplyAll() {
        this.closeModal("applyAllSizes");
        let productSuppliers = this.productYoukado.productSuppliers.filter(e => e.supplier.code === this.productSupplier.supplier.code && this.selectedDeliveryCountries.map(s => s.code).includes(e.deliveryCountryCode));
        for (let productSupplier of productSuppliers) {
            for (let childProductSupplier of productSupplier.childProductSuppliers) {
                if (this.productSupplier.childProductSuppliers.findIndex(e => e.code === childProductSupplier.code) !== -1) {
                    childProductSupplier.dutyFreeBuyingPrice = this.productSupplier.childProductSuppliers.filter(e => e.code === childProductSupplier.code)[0]?.dutyFreeBuyingPrice
                    childProductSupplier.dutyFreeShippingPrice = this.productSupplier.childProductSuppliers.filter(e => e.code === childProductSupplier.code)[0]?.dutyFreeShippingPrice
                    this.updateALLSizes(childProductSupplier, productSupplier);

                }
            }
        }

    }

    updateALLSizes(row: ProductSupplierDtoInterface, productSupplier: ProductSupplierDtoInterface) {
        if (this.isDutyFreeBuyingPriceChange()) {
            let productSupplierPrice = null;
            if (typeof productSupplier.productSupplierPrices != 'undefined' && productSupplier.productSupplierPrices != null && productSupplier.productSupplierPrices.length != 0) {
                productSupplierPrice = productSupplier.productSupplierPrices[0];
            }
            ProductUtils.calculBuyingPrices(row, productSupplierPrice != null ? productSupplierPrice.vat : null);
            ProductUtils.calculAllTotalBuyingPrice(row);
            const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == productSupplier.deliveryCountryProductSupplier.code);
            row.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, row.dutyFreeTotalBuyingPrice);
        }
        if (this.isDutyFreeShippingPriceChange()) {
            ProductUtils.calculAllTaxesIncludedShippingPrice(row, this.vatShippingCosts);
            ProductUtils.calculAllTotalBuyingPrice(row);
            const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == productSupplier.deliveryCountryProductSupplier.code);
            row.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, row.dutyFreeTotalBuyingPrice);
        }
    }


    openModal(id: string) {
        this.supplierService.getSupplierDeliveryCountry(this.productSupplier.supplier.idSupplier).subscribe(
            (response: any) => {
                this.productSupplier.supplier.supplierToDeliveryCountries = response;
                this.getOtherDeliveryCountries();
                this.productSupplierCopy = JSON.parse(JSON.stringify(this.productYoukado.productSuppliers));
                this.productSupplierCopy = this.productSupplierCopy.filter(ps => ps.code == this.productSupplier.code);
                this.getVats();
                this.showFormSizes = false;
                this.modalService.open(id);
                this.getLanguages();
                this.changeTab();
                if (this.tabs != null) {
                    this.tabs.select("detailsProduct");
                }
            },
            (error: any) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );

    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.negativeMargin = false;
        this.negativeCountriesMargin = [];
        return true
    }

    updateValue(event: any, cell: any, rowIndex: any) {
        this.editing[rowIndex + '-' + cell] = false;
        this.sizes[rowIndex][cell] = event.target.value;
        this.sizes = [...this.sizes];
    }

    updateRow(row: any, event: any) {
        if (event.target.name == "size_code") {
            row.code = event.target.value;
            //console.log(event.target.name);
        } else if (event.target.name == "size_size") {
            row.size = event.target.value;
        } else if (event.target.name == "size_stock") {
            row.stock = event.target.value;
        } else if (event.target.name == "size_dutyFreeBuyingPrice") {
            let productSupplierPrice = null;
            if (typeof this.productSupplier.productSupplierPrices != 'undefined' && this.productSupplier.productSupplierPrices != null && this.productSupplier.productSupplierPrices.length != 0) {
                productSupplierPrice = this.productSupplier.productSupplierPrices[0];
            }
            //console.log(this.productSupplier.deliveryCountryProductSupplier);
            row.dutyFreeBuyingPrice = parseFloat(event.target.value);
            ProductUtils.calculBuyingPrices(row, productSupplierPrice != null ? productSupplierPrice.vat : null);
            ProductUtils.calculAllTotalBuyingPrice(row);
            const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == this.productSupplier.deliveryCountryProductSupplier.code);
            row.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, row.dutyFreeTotalBuyingPrice);
        } else if (event.target.name == "size_dutyFreeShippingPrice") {
            let productSupplierPrice = null;
            if (typeof this.productSupplier.productSupplierPrices != 'undefined' && this.productSupplier.productSupplierPrices != null && this.productSupplier.productSupplierPrices.length != 0) {
                productSupplierPrice = this.productSupplier.productSupplierPrices[0];
            }
            //console.log(this.productSupplier.deliveryCountryProductSupplier);
            row.dutyFreeShippingPrice = parseFloat(event.target.value);
            ProductUtils.calculAllTaxesIncludedShippingPrice(row, this.vatShippingCosts);
            ProductUtils.calculAllTotalBuyingPrice(row);
            const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == this.productSupplier.deliveryCountryProductSupplier.code);
            row.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, row.dutyFreeTotalBuyingPrice);
        } else if (event.target.name == "size_state") {
            row.state = event.target.value;
        }
    }

    updateStateRow(row: any, event: any) {
        if (event.target.name == "size_state") {
            row.state = event.target.value;
        }
    }

    showSelectionState(row: any, event: any) {
        row.stateHidden = true;
    }


    toggleExpandRow(row: any) {
        this.table.rowDetail.toggleExpandRow(row);
        this.productSupplier.childProductSuppliers = [...this.productSupplier.childProductSuppliers];
    }

    onDetailToggle(event: any) {
        //console.log('Detail Toggled', event);
    }

    updateFilter(event: any) {
        const val = event.target.value.toLowerCase();

        // filter our data
        const temp = this.temp.filter(function (d: any) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // update the rows
        this.filterRows = temp;
        // Whenever the filter changes, always go back to the first page
        //this.table.offset = 0;
    }

    addSize() {
        if (this.productSupplier.supplier == null || this.productSupplier.dutyFreeShippingPrice == null) {
            this.alertError = true;
            return false;
        }
        for (let productSupplierPrice of this.productSupplier.productSupplierPrices) {
            if (productSupplierPrice.vat == null) {
                this.alertError = true;
                return false;
            }
        }
        if (typeof this.productSupplier.childProductSuppliers == 'undefined' || this.productSupplier.childProductSuppliers == null || this.productSupplier.childProductSuppliers.length == 0) {
            this.productSupplier.childProductSuppliers = [];
        }
        this.productSupplier.childProductSuppliers.push({
            "state": "published",
            "dutyFreeShippingPrice": this.productSupplier.dutyFreeShippingPrice,
            "allTaxesIncludedShippingPrice": this.productSupplier.allTaxesIncludedShippingPrice,
            "productSupplierPrices": this.productSupplier.productSupplierPrices,
            "ecoTaxes": this.productSupplier.ecoTaxes,
            "d3e": this.productSupplier.d3e,
            "sorecop": this.productSupplier.sorecop,
            "allTaxesIncludedEcoTaxes": this.productSupplier.all_taxes_included_eco_taxes,
            "allTaxesIncludedD3e": this.productSupplier.all_taxes_included_d3e,
            "allTaxesIncludedSoreco": this.productSupplier.all_taxes_included_sorecop,
        } as any);
        this.productSupplier.childProductSuppliers = [...this.productSupplier.childProductSuppliers];
        this.tabs.select("sizes");
        return true;
    }

    changeTab() {
        if (this.translationProductSupplier != null) {
            this.translationProductSupplier.select(this.deliveryCountry.code);
        }
    }

    compareFn(option1: any, option2: any): boolean {
        if (option1 && option2) {
            return option1.code === option2.code;
        }
        return false;
    }

    forceSupplier() {
        this.forceSupplierEvent.emit(this.indexProductSupplier);

        let newBestProductSupplier = {} as ProductSupplierDtoInterface;
        if (this.productSupplier.forced) {
            newBestProductSupplier = this.productSupplier;
        } else if (this.productSupplier.isBestChoice) {
            newBestProductSupplier = this.productSupplier;
        } else {
            newBestProductSupplier = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryCode == this.deliveryCountry.code && ps.forced);
            if (newBestProductSupplier == null) {
                newBestProductSupplier = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryCode == this.deliveryCountry.code && ps.isBestChoice);
            }
        }

        this.bestProductSupplierOutput.emit(newBestProductSupplier);

        return true;
    }

    reset() {
        let i: any = 0
        for (let productSupplier of this.productYoukado.productSuppliers) {

            const productSupplierCopy = this.productSupplierCopy.find(ps => ps.deliveryCountryProductSupplier.code == productSupplier.deliveryCountryProductSupplier.code && ps.code == productSupplier.code && ps.supplier.code == productSupplier.supplier.code)
            if (productSupplierCopy != null) {
                productSupplier.code = productSupplierCopy.code;
                productSupplier.stock = productSupplierCopy.stock;
                productSupplier.productSupplierPrices = productSupplierCopy.productSupplierPrices;
                productSupplier.dutyFreeBuyingPrice = productSupplierCopy.dutyFreeBuyingPrice;
                productSupplier.allTaxesIncludedBuyingPrice = productSupplierCopy.allTaxesIncludedBuyingPrice;
                productSupplier.vatAmount = productSupplierCopy.vatAmount;
                productSupplier.dutyFreeShippingPrice = productSupplierCopy.dutyFreeShippingPrice;
                productSupplier.allTaxesIncludedShippingPrice = productSupplierCopy.allTaxesIncludedShippingPrice;
                productSupplier.allTaxesIncludedPublicPrice = productSupplierCopy.allTaxesIncludedPublicPrice;
                productSupplier.ecoTaxes = productSupplierCopy.ecoTaxes;
                productSupplier.d3e = productSupplierCopy.d3e;
                productSupplier.sorecop = productSupplierCopy.sorecop;
                productSupplier.all_taxes_included_eco_taxes = productSupplierCopy.all_taxes_included_eco_taxes;
                productSupplier.all_taxes_included_d3e = productSupplierCopy.all_taxes_included_d3e;
                productSupplier.all_taxes_included_sorecop = productSupplierCopy.all_taxes_included_sorecop;
                productSupplier.dutyFreeTotalBuyingPrice = productSupplierCopy.dutyFreeTotalBuyingPrice;
                productSupplier.allTaxesIncludedTotalBuyingPrice = productSupplierCopy.allTaxesIncludedTotalBuyingPrice;
                productSupplier.margin = productSupplierCopy.margin;
                productSupplier.childProductSuppliers = productSupplierCopy.childProductSuppliers;
                productSupplier.productSupplierPrices = productSupplierCopy.productSupplierPrices;
                productSupplier.translationProductSupplier = productSupplierCopy.translationProductSupplier;
                productSupplier.weight = productSupplierCopy.weight;
                productSupplier.nc8 = productSupplierCopy.nc8;
            }


        }

        this.modalService.close('nauto_' + this.productSupplier.supplier.label + '_' + this.productSupplier.code);
    }

    resetProductSupplier(productSupplier: ProductSupplierDtoInterface) {

        const productSupplierCopy = this.productSupplierCopy.find(ps => ps.deliveryCountryProductSupplier.code == productSupplier.deliveryCountryProductSupplier.code && ps.code == productSupplier.code)
        if (productSupplierCopy != null) {
            productSupplier.code = productSupplierCopy.code;
            productSupplier.stock = productSupplierCopy.stock;
            productSupplier.productSupplierPrices = productSupplierCopy.productSupplierPrices;
            productSupplier.dutyFreeBuyingPrice = productSupplierCopy.dutyFreeBuyingPrice;
            productSupplier.allTaxesIncludedBuyingPrice = productSupplierCopy.allTaxesIncludedBuyingPrice;
            productSupplier.vatAmount = productSupplierCopy.vatAmount;
            productSupplier.dutyFreeShippingPrice = productSupplierCopy.dutyFreeShippingPrice;
            productSupplier.allTaxesIncludedShippingPrice = productSupplierCopy.allTaxesIncludedShippingPrice;
            productSupplier.allTaxesIncludedPublicPrice = productSupplierCopy.allTaxesIncludedPublicPrice;
            productSupplier.ecoTaxes = productSupplierCopy.ecoTaxes;
            productSupplier.d3e = productSupplierCopy.d3e;
            productSupplier.sorecop = productSupplierCopy.sorecop;
            productSupplier.dutyFreeTotalBuyingPrice = productSupplierCopy.dutyFreeTotalBuyingPrice;
            productSupplier.allTaxesIncludedTotalBuyingPrice = productSupplierCopy.allTaxesIncludedTotalBuyingPrice;
            productSupplier.margin = productSupplierCopy.margin;
            productSupplier.childProductSuppliers = productSupplierCopy.childProductSuppliers;
            productSupplier.productSupplierPrices = productSupplierCopy.productSupplierPrices;
            productSupplier.translationProductSupplier = productSupplierCopy.translationProductSupplier;

        }

    }

    onChangeVat() {
        //console.log(this.productSupplier);
    }

    onSubmit(form: NgForm) {

        //console.log("NgForm validation : " + form.valid);
        if (form.valid) {

        }
    }

    getVats() {
        this.vatsService.getVats(this.productSupplier.supplier.expeditionCountrySupplier.idExpeditionCountry).subscribe(
            (response: any) => {
                this.vats = response;
                this.vats.sort((a, b) => a.rate - b.rate);
                this.vatShippingCosts = JSON.parse(JSON.stringify(this.vats.find(v => v.isShippingCosts == true)));
                //console.log("vats : " + JSON.stringify(this.vats));
                if (this.productSupplier.supplier != null && this.productSupplier.supplier.supplierToDeliveryCountries != null && this.productSupplier.supplier.supplierToDeliveryCountries.length != 0) {
                    const supplierToDeliveryCountry = this.productSupplier.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier);
                    supplierToDeliveryCountry.desactivatVatProduct;
                    if (supplierToDeliveryCountry.desactivatVatProduct) {
                        const vat = this.vats.find(v => v.rate == 0);
                        for (let productSupplierPrice of this.productSupplier.productSupplierPrices) {
                            productSupplierPrice.vat = vat;
                            this.disableVat = true;
                        }

                    }
                    if (supplierToDeliveryCountry.desactivateVatShipping) {
                        this.vatShippingCosts.rate = 0;
                    }
                    //this.calculAllTaxesIncludedShippingPrice();
                }
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    addBuyingPrice() {
        const productSupplierPrice = {} as ProductSupplierPriceDtoInterface;
        this.productSupplier.productSupplierPrices.push(productSupplierPrice);
    }

    deleteBuyingPrice(index: number) {
        this.productSupplier.productSupplierPrices.splice(index, 1);
        this.calculBuyingPricesProductSupplier(this.productSupplier);
        this.calculAllTotalBuyingPrice(this.productSupplier);
    }

    deleteProductSupplier(index: number) {
        this.productYoukado.productSuppliers.splice(index, 1);
    }

    changeSalePrice(catalogue: CatalogueDtoInterface) {
        catalogue.allTaxesIncludedSalePrice = parseFloat((catalogue.dutyFreeSalePrice * 1.2).toFixed(2));
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers.length !== 0) {
            for (let productSupplier of this.productYoukado.productSuppliers) {
                if (productSupplier.deliveryCountryProductSupplier.code == this.deliveryCountry.code) {
                    if (productSupplier.forced) {
                        catalogue.margin = productSupplier.margin;
                    }
                }
            }
        }
    }

    calculBuyingPrices() {
        this.negativeCountriesMargin = [];
        this.negativeMargin = false;

        for (let deliveryCountry of this.productYoukado.catalogues) {
            let productSupp = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryProductSupplier.code == deliveryCountry.deliveryCountriesCatalogue.code && ps.supplier.code == this.productSupplier.supplier.code);

            if (productSupp != undefined) {
                for (let prices of productSupp.productSupplierPrices) {
                    prices.allTaxesIncludedBuyingPrice = parseFloat(((prices.dutyFreeBuyingPrice * (prices.vat != null ? prices.vat.rate / 100 : 0)) + prices.dutyFreeBuyingPrice).toFixed(2));
                    prices.vatAmount = parseFloat((prices.allTaxesIncludedBuyingPrice - prices.dutyFreeBuyingPrice).toFixed(2));
                    this.calculBuyingPricesProductSupplier(productSupp);
                    this.calculAllTotalBuyingPrice(productSupp);
                }
                if (productSupp.margin < 0) {
                    this.negativeMargin = true;
                    this.negativeCountriesMargin.push(deliveryCountry.deliveryCountriesCatalogue.label);
                }
            }
        }
    }

    calculBuyingPricesProductSupplier(productSupplier: ProductSupplierDtoInterface) {
        productSupplier.dutyFreeBuyingPrice = null;
        productSupplier.allTaxesIncludedBuyingPrice = null;
        productSupplier.vatAmount = null;
        for (let productSupplierPrice of this.productSupplier.productSupplierPrices) {
            productSupplier.dutyFreeBuyingPrice = parseFloat((productSupplier.dutyFreeBuyingPrice + productSupplierPrice.dutyFreeBuyingPrice).toFixed(2));
            productSupplier.allTaxesIncludedBuyingPrice = parseFloat((productSupplier.allTaxesIncludedBuyingPrice + productSupplierPrice.allTaxesIncludedBuyingPrice).toFixed(2));
            productSupplier.vatAmount = parseFloat((productSupplier.vatAmount + productSupplierPrice.vatAmount).toFixed(2));
        }
    }

    calculAllTaxesIncludedShippingPrice() {
        for (let productSupplier of this.productYoukado.productSuppliers) {
            if (productSupplier.code == this.productSupplier.code) {
                productSupplier.allTaxesIncludedShippingPrice = parseFloat(((productSupplier.dutyFreeShippingPrice * (this.vatShippingCosts != null ? this.vatShippingCosts.rate / 100 : 0)) + productSupplier.dutyFreeShippingPrice).toFixed(2));
            }
        }
        // this.getVats();
        this.calculAllTotalBuyingPrice(this.productSupplier);
    }

    calculAllTotalBuyingPrice(productSupplier: ProductSupplierDtoInterface) {
        productSupplier.all_taxes_included_eco_taxes = (productSupplier.ecoTaxes != null ? productSupplier.ecoTaxes : 0) + ((this.selectedTauxTaxesTvaValue / 100) * (productSupplier.ecoTaxes != null ? productSupplier.ecoTaxes : 0));
        productSupplier.all_taxes_included_d3e = (productSupplier.d3e != null ? productSupplier.d3e : 0) + ((this.selectedTauxTaxesTvaValue / 100) * (productSupplier.d3e != null ? productSupplier.d3e : 0));
        productSupplier.all_taxes_included_sorecop = (productSupplier.sorecop != null ? productSupplier.sorecop : 0) + ((this.selectedTauxTaxesTvaValue / 100) * (productSupplier.sorecop != null ? productSupplier.sorecop : 0));

        productSupplier.dutyFreeTotalBuyingPrice = productSupplier.dutyFreeBuyingPrice + (productSupplier.dutyFreeShippingPrice != null ? productSupplier.dutyFreeShippingPrice : 0) + (productSupplier.ecoTaxes != null ? productSupplier.ecoTaxes : 0) + (productSupplier.d3e != null ? productSupplier.d3e : 0) + (productSupplier.sorecop != null ? productSupplier.sorecop : 0);
        productSupplier.allTaxesIncludedTotalBuyingPrice = productSupplier.allTaxesIncludedBuyingPrice + (productSupplier.allTaxesIncludedShippingPrice != null ? productSupplier.allTaxesIncludedShippingPrice : 0) + (productSupplier.all_taxes_included_eco_taxes != null ? productSupplier.all_taxes_included_eco_taxes : 0) + (productSupplier.all_taxes_included_d3e != null ? productSupplier.all_taxes_included_d3e : 0) + (productSupplier.all_taxes_included_sorecop != null ? productSupplier.all_taxes_included_sorecop : 0);
        const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);
        productSupplier.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, productSupplier.dutyFreeTotalBuyingPrice);
    }

    editSizeStatus(value: any, rowIndex: number) {
        console.log(JSON.stringify(rowIndex));
    }

    redirectNegativeMargin() {
        this.closeModal("negativeMarginAlert");
    }

    updateEmptyLibilleSupplierItemLg(label: string) {
        for (let translation of this.productSupplier?.translationProductSupplier) {
            if (!translation.label) {
                translation.label = label;
            }
        }
    }
}
