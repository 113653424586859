<div class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>{{"components.suppliers.title" | translate}}</h4>
        </div>
        <div class="col-sm-6">
           <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a class="button xx-small pull-right" (click)="export()">
                        <i class="fa fa-file" aria-hidden="true" style="font-size: 18px;"></i>
                        {{'components.suppliers.export' | translate}}
                    </a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                        translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>
    <!-- main body -->
    <div class="row">
        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <form [formGroup]="formGroupSearch">
                        <div class="form-row align-items-center">
                            <div class="col-sm-3">
                                <div class="form-group mb-0" style="position: relative;">
                                    <input placeholder="{{'components.suppliers.search.keywords' | translate}}"
                                           (input)="search()" autofocus formControlName="keywords"
                                           class="form-control search-input">
                                    <button class="search-button" type="submit" style="">
                                        <i class="fa fa-search not-click"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group mb-0">
                                    <ng-multiselect-dropdown id="deliveryCountries" autofocussearchmultiselect
                                                             formControlName="deliveryCountries" (onSelect)="search()"
                                                             (onDeSelect)="search()" (onDeSelectAll)="search()"
                                                             [settings]="{
                                                                        singleSelection: false,
                                                                        idField: 'idDeliveryCountry',
                                                                        textField: 'code',
                                                                        allowSearchFilter: true,
                                                                        enableCheckAll: false
                                                                    }"
                                                             placeholder="{{'components.suppliers.search.deliveryCountries' | translate}}"
                                                             [data]="availableDeliveryCountries" class="form-control">
                                        <!--                                            <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">-->
                                        <!--suppress JSUnusedGlobalSymbols -->
                                        <ng-template #optionsTemplate let-option="option">
                                            <div>
                                                <img [src]="'assets/images/flags/' + option + '.png'"
                                                     style="width: 18px; margin-right: 2px;" alt=""/>
                                                {{'core.countries.' + option.toLowerCase() | translate}}
                                            </div>
                                        </ng-template>
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group mb-0">
                                    <ng-multiselect-dropdown id="expeditionCountries" autofocussearchmultiselect
                                                             formControlName="expeditionCountries" (onSelect)="search()"
                                                             (onDeSelect)="search()" (onDeSelectAll)="search()"
                                                             [settings]="{
                                                                        singleSelection: false,
                                                                        idField: 'idExpeditionCountry',
                                                                        textField: 'code',
                                                                        allowSearchFilter: true,
                                                                        enableCheckAll: false
                                                                    }"
                                                             placeholder="{{'components.suppliers.search.expeditionCountres' | translate}}"
                                                             [data]="availableExpeditionCountries" class="form-control">
                                        <!--                                            <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">-->
                                        <!--suppress JSUnusedGlobalSymbols -->
                                        <ng-template #optionsTemplate let-option="option">
                                            <div>
                                                <img [src]="'assets/images/flags/' + option + '.png'"
                                                     style="width: 18px; margin-right: 2px;" alt=""/>
                                                {{'core.countries.' + option.toLowerCase() | translate}}
                                            </div>
                                        </ng-template>
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>

                            <div class="col-md-2 remember-checkbox"
                                 style="padding-top: 11px;display: flex;justify-content: center;align-items: center;">
                                <input id="searchTypeAuto"
                                       formControlName="searchTypeAuto"
                                       type="checkbox"
                                       (click)="search()"
                                       class="form-control">
                                <label style="padding-left: 24px;" for="searchTypeAuto">Automatique</label>
                            </div>

                            <div class="col-md-2-5 remember-checkbox"
                                 style="padding-top: 11px;display: flex;justify-content: center;align-items: center;">
                                <input id="searchTypeManu"
                                       formControlName="searchTypeManu"
                                       type="checkbox"
                                       (click)="search()"
                                       class="form-control">
                                <label style="padding-left: 24px;" for="searchTypeManu">{{'components.suppliers.search.type' | translate}}</label>
                            </div>

                            <div *ngIf="hasAccess" class="col-sm-2">
                                <a class="button xx-small pull-right" (click)="add()">
                                    <i class="fa fa-plus" aria-hidden="true" style="font-size: 18px;"></i>
                                    {{'components.suppliers.search.add' | translate}}
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-12">

            <div *ngIf="suppliers && suppliers.length" style="padding-left: 15px;padding-bottom: 5px;" class="row">
                <h5 class="text-primary"><span style="color:grey">
            Total fournisseurs :</span> {{totalElements | number:'1.0-0':'fr'}}
                </h5>
            </div>

            <div class="row">
                <div *ngFor="let supplier of suppliers" (click)="edit(supplier)"
                     class="col-xl-4 col-lg-3 col-md-4 col-sm-6 mb-30">

                    <div class="card card-statistics h-100 card_suppliers">
                        <div class="card-body" style="cursor: pointer;">
                            <h6 class="card-title">{{supplier.label | lowercase}}</h6>

                            <ul class="addresss-info invoice-addresss list-unstyled">
                                <li>
                                    <b>Code : </b><span>{{supplier.code}}</span>
                                </li>
                                <li style="line-height: 32px;display:flex;"><b>Pays d'expédition : </b>
                                    <img style="margin-top: 5px;margin-left: 5px;width: 30px; margin-right: 2px;height:20px;cursor:pointer"
                                         src="assets/images/countries/{{supplier.expeditionCountrySupplier.code}}.jpg">
                                </li>
                                <li style="line-height: 32px;display:flex;"><b>Pays de livraison : </b>
                                    <u style="display:flex;">
                                        <li style="padding-left: 5px;text-align: center;position: relative;"
                                            *ngFor="let supplierToDeliveryCountry of supplier.supplierToDeliveryCountries; let index = index;">
                                            <i style="position: absolute;top: -6px;left: 13px;" ngbTooltip="{{supplierToDeliveryCountry.typeCatalogue && supplierToDeliveryCountry.typeCatalogue == 'MANU' ? 'Manuel' : 'Automatique'}}"  class=" fa {{supplierToDeliveryCountry.typeCatalogue && supplierToDeliveryCountry.typeCatalogue == 'MANU' ? 'fa-hand-stop-o' : 'fa-life-ring'}}  mr-10"></i>
                                            <img style="width: 30px; margin-right: 2px;height:20px;cursor:pointer"
                                                    src="assets/images/countries/{{supplierToDeliveryCountry.deliveryCountryCode}}.jpg">
                                                <br>
                                                <span>
                                                    <i ngbTooltip="{{supplierToDeliveryCountry.orderType && supplierToDeliveryCountry.orderType == 'INACTIF' ? 'Désactivé' : 'Activé'}}" class="fa fa-circle {{supplierToDeliveryCountry.orderType == 'INACTIF' ? 'text-danger' : 'text-success'}} "></i>
                                                    <b style="margin-left: 5px;">{{supplierToDeliveryCountry.deliveryCountryCode}}</b>
                                                </span>
                                        </li>
                                    </u>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm-mt-20" *ngIf="suppliers && suppliers.length">
                <pagination [pagination]="supplierPageable" (onPaginate)="paginate($event)"></pagination>
            </div>
        </div>
    </div>
</div>

<app-model class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true" id="editModal">
    <form *ngIf="supplier" [formGroup]="formGroup" (submit)="submit()">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title" id="exampleModalLongTitle">
                        <div class="section-title mb-10">
                            <span style="color:red">* Champs obligatoires</span>
                        </div>
                    </div>
                    <p class="mb-4">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                                (click)="closeModalForm('editModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <span class="pull-right mr-3">

                            <label style="padding-right: 50px;font-size: 16px;" class="control-label">Créé par :  <b style="text-decoration: underline">{{supplier.origin}}</b> le <b style="text-decoration: underline"> {{ supplier.createdDate | date:'dd/MM/yyyy' }}</b></label>

                            <i *ngIf="supplier && !supplier.idSupplier" class="fa fa-file" aria-hidden="true"
                               style="color: deepskyblue; font-size: 21px;"></i>
                            <i *ngIf="supplier && supplier.idSupplier" class="fa fa-pencil text-primary"
                               aria-hidden="true" style="font-size: 21px;"></i>
                        </span>
                    </p>
                </div>
                <div class="modal-body">
                    <div class="card card-statistics mb-30">
                        <div class="card-body">
                            <div class="form-row align-items-center">
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="fcode">{{"components.suppliers.form.code"
                                        |
                                        translate}}</label><span style="color:red">*</span>
                                    <input id="fcode" formControlName="code"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.code.dirty || formGroup.controls.code.touched) && formGroup.controls.code.invalid}"
                                           type="text" class="form-control" readonly/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="label">{{"components.suppliers.form.label"
                                        |
                                        translate}}</label><span style="color:red">*</span>
                                    <input id="label" formControlName="label"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.label.dirty || formGroup.controls.label.touched) && formGroup.controls.label.invalid}"
                                           type="text" style="font-weight: 500;" class="form-control"/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label"
                                           for="expeditionCountry">{{"components.suppliers.form.expeditionCountry"
                                        |
                                        translate}}</label><span style="color:red">*</span>
                                    <ng-multiselect-dropdown id="expeditionCountry" autofocussearchmultiselect
                                                             formControlName="expeditionCountry"
                                                             [(ngModel)]="expeditionCountry"
                                                             [ngClass]="{'is-invalid':supplier && (formGroup.controls.expeditionCountry.dirty || formGroup.controls.expeditionCountry.touched) && formGroup.controls.expeditionCountry.invalid}"
                                                             [settings]="{
                                                                        singleSelection: true,
                                                                        idField: 'code',
                                                                        textField: 'code',
                                                                        allowSearchFilter: true,
                                                                        enableCheckAll: false
                                                                    }" [data]="availableDeliveryCountries"
                                                             placeholder="&nbsp;" class="form-control form-control-lg">
                                        <!--suppress JSUnusedGlobalSymbols -->
                                        <ng-template #optionsTemplate let-option="option">
                                            <div>
                                                <img [src]="'assets/images/flags/' + option + '.png'"
                                                     style="width: 18px; margin-right: 2px;" alt=""/>
                                                {{'core.countries.' + option.toLowerCase() | translate}}
                                            </div>
                                        </ng-template>
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="address1">{{"components.suppliers.form.address1" |
                                        translate}}</label>
                                    <input id="address1" formControlName="address1" type="text" class="form-control"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.address1.dirty || formGroup.controls.address1.touched) && formGroup.controls.address1.invalid}"/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="address2">{{"components.suppliers.form.address2" |
                                        translate}}</label>
                                    <input id="address2" formControlName="address2" type="text" class="form-control"/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                    <label class="control-label" for="zip">{{"components.suppliers.form.zip"
                                        |
                                        translate}}</label>
                                    <input id="zip" formControlName="zip" type="text" class="form-control"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.zip.dirty || formGroup.controls.zip.touched) && formGroup.controls.zip.invalid}"/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                    <label class="control-label" for="city">{{"components.suppliers.form.city" |
                                        translate}}</label>
                                    <input id="city" formControlName="city" type="text" class="form-control"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.city.dirty || formGroup.controls.city.touched) && formGroup.controls.city.invalid}"/>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                    <label class="control-label" for="phone">{{"components.suppliers.form.phone" |
                                        translate}}</label>
                                    <input id="phone" formControlName="phone" type="text" class="form-control"
                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.phone.dirty || formGroup.controls.phone.touched) && formGroup.controls.phone.invalid}"/>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label"
                                           for="productType">{{"components.suppliers.form.productType" |
                                        translate}}</label><span style="color:red">*</span>
                                    <select id="productType" formControlName="productType"
                                            [ngClass]="{'is-invalid':supplier && (formGroup.controls.productType.dirty || formGroup.controls.productType.touched) && formGroup.controls.productType.invalid}"
                                            class="form-control form-control-lg">
                                        <option *ngFor="let availableProductType of availableProductTypes"
                                                [ngValue]="availableProductType">
                                            {{"components.suppliers.form.availableProductTypes." +
                                        availableProductType
                                            | translate}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="zip">{{"components.suppliers.form.language" |
                                        translate}}</label><span style="color:red">*</span>
                                    <!--                                    <input id="language"-->
                                    <!--                                           formControlName="language"-->
                                    <!--                                           [ngClass]="{'is-invalid':supplier && (formGroup.controls.language.dirty || formGroup.controls.language.touched) && formGroup.controls.language.invalid}"-->
                                    <!--                                           type="text"-->
                                    <!--                                           class="form-control"/>-->
                                    <ng-multiselect-dropdown id="language" autofocussearchmultiselect
                                                             formControlName="language"
                                                             [(ngModel)]="language"
                                                             [settings]="{
                                                                        singleSelection: true,
                                                                        idField: 'code',
                                                                        textField: 'code',
                                                                        allowSearchFilter: true,
                                                                        enableCheckAll: false
                                                                    }" [data]="availableLanguages" placeholder="&nbsp;"
                                                             class="form-control form-control-lg">
                                    </ng-multiselect-dropdown>
                                </div>


                                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                    <label class="control-label" for="ref">Réferent achat</label><span style="color:red">*</span>
                                    <ng-multiselect-dropdown id="ref" autofocussearchmultiselect
                                                             formControlName="referent"
                                                             [(ngModel)]="referent"
                                                             [settings]="{
                                                                        singleSelection: true,
                                                                        idField: 'login',
                                                                        textField: 'name',
                                                                        allowSearchFilter: true,
                                                                        enableCheckAll: false
                                                                    }" [data]="referents" placeholder="&nbsp;"
                                                             class="form-control form-control-lg">

                                    </ng-multiselect-dropdown>
                                </div>

                                <div class="form-check" style="margin-left: 25px;padding-top: 23px;">
                                    <input id="dropShipping" formControlName="dropShipping" type="checkbox"
                                           class="form-check-input">
                                    <label class="form-check-label" style="display: block;margin-bottom: 10px;">
                                        Drop Shipping
                                    </label>
                                    <input id="externe" formControlName="externe" type="checkbox"
                                           class="form-check-input">
                                    <label class="form-check-label" style="display: block;margin-bottom: 10px;">
                                        Externe
                                    </label>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="card card-statistics mb-30">
                        <div class="card-body">
                            <div class="col-sm-12 country-block">
                                <div class="country-space"
                                     *ngFor="let destinationCountryControl of getDestinationCountriesControls; let i = index"
                                     style="display: inline-block;">
                                    <span (click)="destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.codeSequence && setActiveCountry(destinationCountryControl?.value?.codeSequence)"
                                          [ngClass]="{'country-tabs-image-active': destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.codeSequence && destinationCountryControl.value.codeSequence == deliveryCountryActif, 'country-tabs-image-inactive': destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.codeSequence && destinationCountryControl.value.codeSequence !== deliveryCountryActif}">
                                        <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                             src="assets/images/countries/{{destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.code ? destinationCountryControl.value.code[0].code : ''}}.jpg">
                                    </span>
                                    <i ngbTooltip="Supprimer" style="cursor: pointer;" *ngIf="findRecentCountries(destinationCountryControl)"
                                       (click)="removeDestinationCountry(i)"
                                       class="fa fa-trash-o">
                                    </i>
                                    <h6 [ngClass]="{'country-tabs-label': destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.codeSequence && destinationCountryControl.value.codeSequence == deliveryCountryActif}"
                                        style="text-align: center;width: 38px;;cursor:pointer">
                                        {{destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.code ? destinationCountryControl.value.code[0].code : ''}}
                                    </h6>
                                </div>
                                <div *ngIf="availableDeliveryCountriesToAdd != undefined && availableDeliveryCountriesToAdd.length > 0"
                                     style="display: inline-block;padding-left:20px;"
                                     [ngClass]="{'absolutePosition': supplier.supplierToDeliveryCountries != undefined && supplier.supplierToDeliveryCountries.length > 0}">
                                    <a (click)="addDeliveryCountryModal()" href="javascript:void(0);"
                                       data-toggle="tooltip" placement="right" title=""
                                       ngbTooltip="Ajouter un pays">
                                       <i style="margin-left: 25px;height:35px;cursor:pointer;font-size: 26px;" class="fa fa-plus text-success action-button"></i>
                                    </a>
                                </div>

                                <a class="btn btn-primary float-right text-white" *ngIf="isRecentCountries"  (click)="updateFieldsForAllCountries()">Appliquer les modifications</a>
                            </div>

                            <div formArrayName="destinationCountries"
                                 *ngFor="let destinationCountryControl of getDestinationCountriesControls; let i = index"
                                 class="form-row align-items-center">
                                <ng-container
                                        *ngIf="destinationCountryControl && destinationCountryControl.value && destinationCountryControl.value.codeSequence && destinationCountryControl.value.codeSequence == deliveryCountryActif"
                                        [formGroupName]="i">
                                    <div style="display: none" class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.destinationCountry" |
                                            translate}}</label>
                                        <ng-multiselect-dropdown id="destinationCountryCode_{{i}}"
                                                                 autofocussearchmultiselect formControlName="code"
                                                                 [settings]="{
                                                                    singleSelection: true,
                                                                    idField: 'idDeliveryCountry',
                                                                    textField: 'code',
                                                                    allowSearchFilter: true,
                                                                    enableCheckAll: false
                                                                }" [data]="availableDeliveryCountries"
                                                                 [ngClass]="{'is-invalid':supplier && (
                                                                    getAbstractControllerAsFromGroup(destinationCountryControl).controls.code.dirty
                                                                    || getAbstractControllerAsFromGroup(destinationCountryControl).controls.code.touched
                                                                 ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.code.invalid}"
                                                                 placeholder="&nbsp;"
                                                                 class="form-control form-control-lg">
                                            <!--suppress JSUnusedGlobalSymbols -->
                                            <ng-template #optionsTemplate let-option="option">
                                                <div>
                                                    <img [src]="'assets/images/flags/' + option + '.png'"
                                                         style="width: 18px; margin-right: 2px;" alt=""/>
                                                    {{'core.countries.' + option.toLowerCase() | translate}}
                                                </div>
                                            </ng-template>
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.code"
                                            |
                                            translate}}</label><span style="color:red">*</span>
                                        <input id="codeSequence_{{i}}" formControlName="codeSequence"
                                               type="text" class="form-control" readonly/>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.email" |
                                            translate}}</label><span style="color:red">*</span>
                                        <input id="email_{{i}}" formControlName="email"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.email.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.email.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.email.invalid}"
                                               type="text"
                                               class="form-control">
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.notification" |
                                            translate}}</label><span style="color:red">*</span>
                                        <input id="notification_{{i}}" formControlName="notification"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.notification.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.notification.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.notification.invalid}"
                                               type="text" class="form-control">
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.shippingCosts" |
                                            translate}}</label>
                                        <input id="shippingCosts_{{i}}" formControlName="shippingCosts" type="text"
                                               class="form-control"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.shippingCosts.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.shippingCosts.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.shippingCosts.invalid}">
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.thirdParty" |
                                            translate}}</label><span style="color:red">*</span>
                                        <input id="thirdParty_{{i}}" formControlName="thirdParty" type="text"
                                               class="form-control"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.thirdParty.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.thirdParty.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.thirdParty.invalid}"/>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.type" |
                                            translate}}</label><span style="color:red">*</span>
                                        <select id="typeCatalogue{{i}}" formControlName="typeCatalogue"
                                                [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.typeCatalogue.dirty ||
                                                 getAbstractControllerAsFromGroup(destinationCountryControl).controls.typeCatalogue.touched) &&
                                                  getAbstractControllerAsFromGroup(destinationCountryControl).controls.typeCatalogue.invalid}"
                                                class="form-control form-control-lg">
                                            <option *ngFor="let availableTypes of typeSupplierList"
                                                    [ngValue]="availableTypes.code">
                                                {{"components.suppliers.form.availableTypes." +
                                            availableTypes.code
                                                | translate}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.actualisationType" |
                                            translate}}</label><span style="color:red">*</span>
                                        <select id="actualisationType_{{i}}" formControlName="actualisationType"
                                                [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.actualisationType.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.actualisationType.touched)
                                                && getAbstractControllerAsFromGroup(destinationCountryControl).controls.actualisationType.invalid}"
                                                class="form-control form-control-lg">
                                            <option *ngFor="let availableActualisationType of typeImportCommandSupplierList"
                                                    [ngValue]="availableActualisationType.code">
                                                {{"components.suppliers.form.availableActualisationTypes." +
                                            availableActualisationType.label
                                                | translate}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">{{"components.suppliers.form.orderType" |
                                            translate}}</label><span style="color:red">*</span>
                                        <select id="orderType_{{i}}" formControlName="orderType"
                                                [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.orderType.dirty ||
                                                 getAbstractControllerAsFromGroup(destinationCountryControl).controls.orderType.touched) &&
                                                  getAbstractControllerAsFromGroup(destinationCountryControl).controls.orderType.invalid}"
                                                class="form-control form-control-lg">
                                            <option *ngFor="let availableOrderType of typeSendCommandSupplierList"
                                                    [ngValue]="availableOrderType.code">
                                                {{"components.suppliers.form.availableOrderTypes." +
                                            availableOrderType.label
                                                | translate}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">Adresse de contact relance</label>
                                        <input id="reminderEmail_{{i}}" formControlName="reminderEmail" type="text"
                                               class="form-control"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmail.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmail.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmail.invalid}">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label class="control-label">Délai relance (en jours) </label>
                                        <input id="reminderEmailDelay_{{i}}" formControlName="reminderEmailDelay" type="number"
                                               class="form-control"
                                               [ngClass]="{'is-invalid':supplier && (
                                                getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmailDelay.dirty
                                                || getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmailDelay.touched
                                             ) && getAbstractControllerAsFromGroup(destinationCountryControl).controls.reminderEmailDelay.invalid}">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-4 col-xl-3" style="padding-left: 23px;">
                                        <input id="facturation_{{i}}" formControlName="facturation" type="checkbox"
                                               class="form-check-input">
                                        <label class="form-check-label" style="display: block;margin-bottom: 10px;">
                                            Entité de facturation
                                        </label>
                                    </div>

                                    <div>
                                        {{'components.suppliers.form.disableVat' | translate}}
                                    </div>
                                    <div class="form-check" style="margin-left: 25px;">
                                        <input id="vatProduct_{{i}}" formControlName="vatProduct" type="checkbox"
                                               class="form-check-input">
                                        <label class="form-check-label" style="display: block;margin-bottom: 10px;">
                                            {{'components.suppliers.form.vatProduct' | translate}}
                                        </label>
                                        <input id="vatShipping_{{i}}" formControlName="vatShipping" type="checkbox"
                                               class="form-check-input">
                                        <label class="form-check-label" style="display: block;margin-bottom: 10px;">
                                            {{'components.suppliers.form.vatShipping' | translate}}
                                        </label>
                                    </div>



                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">



                    <a *ngIf="supplier && hasAccess" class="button medium" (click)="submit()">
                        <i class="fa fa-floppy-o" aria-hidden="true" style="font-size: 21px;"></i>
                        {{'components.suppliers.form.save' | translate}}
                    </a>
                    <a *ngIf="supplier && hasAccess" class="button gray medium" (click)="closeModalForm('editModal')">
                        <i class="fa fa-times" aria-hidden="true" style="font-size: 21px;"></i>
                        {{'components.suppliers.form.cancel' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </form>
</app-model>

<app-model style="z-index: 10000;" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog"
           aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="deliveryCountries">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title" id="exampleModalLongTitle">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Ajouter un pays de livraison</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('deliveryCountries')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;">
                    <div class="mb-2">
                        <ng-multiselect-dropdown id="deliveryCountries" name="deliveryCountries"
                                                 [settings]="dropdownSettingsDeliveryCountries"
                                                 [(ngModel)]="selectedDeliveryCountries"
                                                 [placeholder]="'Sélectionner pays de livraison'"
                                                 [data]="availableDeliveryCountriesToAdd">

                            <ng-template #optionsTemplate let-option="option">
                                <div>
                                    <img [src]="'assets/images/flags/' + option + '.png'"
                                         style="width: 18px; margin-right: 2px;"/>
                                    {{option}}
                                </div>
                            </ng-template>
                        </ng-multiselect-dropdown>
                        <!-- <p class="error help-block" *ngIf="f.invalid && deliveryCountryToAddVar.errors?.required">
                            Merci de sélectionner un pays de livraison</p> -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('deliveryCountries')">Fermer
                </button>
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="addDeliveryCountry()">Ajouter
                </button>
            </div>

        </div>
    </div>
</app-model>
