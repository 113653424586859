<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="pricesEnseignes"
           aria-hidden="true" id="pricesEnseignes">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Prix par enseigne</h3>
                    </div>
                </div>
                <img style="margin-right: 42px;" class="flag-deliveryCountry"
                     src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('pricesEnseignes')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">

                    <!-- Enseignes -->
                    <div class="col-md-4 mb-3">
                        <label class="control-label" for="enseignes">{{'components.product.tabs.details.enseignes' |
                            translate}}</label>
                        <div class="mb-2">
                            <ng-multiselect-dropdown id="enseignes"
                                                     [(ngModel)]="enseignesSelectedList"
                                                     [placeholder]="'Sélectionner une enseigne'" name="enseigne"
                                                     [data]="enseignes"
                                                     [settings]="dropdownSettingsEnseignes"
                                                     (onSelect)="changeEnseigne($event)"
                                                     (onDeSelect)="changeEnseigne($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <ngx-datatable class="bootstrap mb-30" [rows]="priceEnseignes" [rowHeight]="'auto'"
                                       [messages]="{emptyMessage:''}" [reorderable]="true">
                            <ngx-datatable-footer></ngx-datatable-footer>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Enseigne
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.nomEnseigne}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Prix vente ht min
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.minPrice}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Prix vente ht max
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.maxPrice}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Ratio
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.ratio}}%
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Prix vente HT
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.dutyFreeSalePrice}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Prix vente TTC
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.allTaxesIncludedSalePrice}}
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Marge
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.margin}}%
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="ean" [width]="200">
                                <ng-template ngx-datatable-header-template>
                                    Points
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>{{row.gains}}
                                </ng-template>
                            </ngx-datatable-column>


                        </ngx-datatable>
                    </div>
                    <div class="sm-mt-20" *ngIf="priceEnseignes && priceEnseignes.length">
                        <pagination [pagination]="priceEnseignesPageable" (onPaginate)="search($event)">
                        </pagination>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('pricesEnseignes')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertSupplierOutOfStock">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert repture de stock fournisseur</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('alertSupplierOutOfStock')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Impossible de forcer ce fournisseur : le stock est inférieur à zéro
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('alertSupplierOutOfStock')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>
<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertNoSupplierAvailable">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert disponibilité produit ou marge négative</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('alertNoSupplierAvailable')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Le produit n'est disponible chez aucun fournisseur ou la marge est négative
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('alertNoSupplierAvailable')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>
<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertNoAllTaxesIncludedSalePrice">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert prix de vente non renseigné</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('alertNoAllTaxesIncludedSalePrice')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Aucun prix de vente est renseigné
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('alertNoAllTaxesIncludedSalePrice')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>
<div>


    <ngb-alert style="text-align: center;" (close)="validationData.productSupplierKo=false" *ngIf="validationData.productSupplierKo"
               type="danger">
        Le fournisseur que vous avez ajouté existe déjà, cliquer <a (click)="openExternalDetails(validationData.externalId)" style="color: #505094;text-decoration: underline;cursor:pointer;">ici</a> pour voir le produit
    </ngb-alert>

    <div class="card card-statistics mb-30">
        <div class="card-body">
            <h5 class="card-title">
                Fournisseurs
            </h5>
            <label *ngIf="validationData.productsSupplier" style="color:red" class="control-label">
                *Merci d'ajouter des fournisseurs
            </label>
            <i (click)="supplierDetails()" ngbTooltip="Ajouter un fournisseur manuel" class="fa fa-plus text-success action-button" style="font-size: 26px;position: absolute;top: 18px;right: 170px;cursor: pointer;"></i>
            <img class="flag-deliveryCountry" src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">
            <div class="row">
                <div *ngFor="let productSupplier of productYoukado.productSuppliers; let index = index;"
                     class="{{productSupplier.deliveryCountryProductSupplier != null && productSupplier.deliveryCountryProductSupplier.code==deliveryCountry.code ? 'col-xl-3 col-lg-6 col-sm-6 mb-30' : ''}}">
                    <div>
                        <app-products-supplier-item
                                *ngIf="productSupplier.deliveryCountryProductSupplier.code==deliveryCountry.code"
                                (forceSupplierEvent)="forceSupplier($event)"
                                [productSuppliersCopy]="productSuppliersCopy"
                                [deliveryCountry]="deliveryCountry" [productYoukado]="productYoukado"
                                [productSupplier]="productSupplier" [indexProductSupplier]="index"
                                [typeCatalogue]="getTypeCatalogue(productSupplier)"
                                (bestProductSupplierOutput)="updateBestSupplier($event)">
                        </app-products-supplier-item>
                    </div>
                </div>
                <!-- <div class="col-xl-2 col-lg-6 col-sm-6 mb-30">
                    <div class="card card-statistics h-100">
                        <div style="cursor:pointer;text-align: center;" class="card-body">
                            <h6 class="card-title">Ajouter un fournisseur</h6>
                            <img (click)="supplierDetails()" style="width: 70%;" class="img-fluid mb-20"
                                 src="assets/images/add-icon.PNG" alt="">
                        </div>
                    </div>
                </div> -->
                <app-model class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                           aria-labelledby="myLargeModalLabel" aria-hidden="true" id="productsSupplierToAdd">

                    <form id="productSupplierAddForm" name="productSupplierAddForm"
                          (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="modal-title" id="exampleModalLongTitle">
                                        <div class="section-title mb-10">
                                            <h2 class="float-left">Ajouter un fournisseur</h2>
                                            <h6 style="padding-left: 50px;color: red;" *ngIf="f.invalid"
                                                class="float-left">*Merci
                                                de remplir tout les champs obligatoires</h6>
                                        </div>
                                    </div>
                                    <button style="position: absolute;right: 16%;" class="btn btn-secondary"
                                            data-dismiss="modal" (click)="addSize()">Ajouter une taille
                                    </button>
                                    <img style="margin-right: 42px;" class="flag-deliveryCountry"
                                         src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                                            (click)="closeModal('productsSupplierToAdd')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="tab nav-bt">
                                        <ngb-alert style="text-align: center;" (close)="alertError=false"
                                                   *ngIf="alertError" type="danger">Merci de choisir un fournisseur, la
                                            TVA et
                                            les frais de
                                            port
                                        </ngb-alert>
                                        <div class="card card-statistics mb-30">
                                            <div class="card-body">
                                                <div class="tab nav-bt">
                                                    <ngb-tabset #tabs class="tab nav-center">
                                                        <ngb-tab id="detailsProduct" title="Infos produit fournisseur">
                                                            <ng-template ngbTabContent>

                                                                <div class="form-row">
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="productsSupplierToAddCode">Code*</label>
                                                                        <div class="mb-2">
                                                                            <input type="text" required
                                                                                   class="form-control"
                                                                                   id="productsSupplierToAddCode"
                                                                                   name="productsSupplierToAddCode"
                                                                                   [(ngModel)]="productsSupplierToAdd.code"
                                                                                   #productsSupplierToAddCode="ngModel"/>
                                                                            <em class="error help-block"
                                                                                *ngIf="f.invalid && productsSupplierToAddCode.errors?.required">Merci
                                                                                de saisir un
                                                                                code</em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="stock">Stock*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required
                                                                                   class="form-control" id="stock"
                                                                                   name="stock"
                                                                                   [(ngModel)]="productsSupplierToAdd.stock"
                                                                                   #stock="ngModel"/>
                                                                            <em class="error help-block"
                                                                                *ngIf="f.invalid && stock.errors?.required">Merci
                                                                                de saisir un stock</em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="supplier">Fournisseur*</label>
                                                                        <div class="mb-2">
                                                                            <ng-multiselect-dropdown id="supplier"
                                                                                                     [(ngModel)]="selectedSupplier"
                                                                                                     [placeholder]="'Sélectionner un fournisseur'"
                                                                                                     name="supplier"
                                                                                                     [data]="suppliers"
                                                                                                     [settings]="dropdownSettings"
                                                                                                     (onSelect)="onChangeSupplier($event)"
                                                                                                     (onDeSelect)="onItemDeSelect($event)">
                                                                            </ng-multiselect-dropdown>

                                                                            <em class="error help-block"
                                                                                *ngIf="errorSupplier">Merci
                                                                                de
                                                                                sélectionner un fournisseur</em>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="productsSupplierToAdd?.supplier?.code == 'FRS729'  || productsSupplierToAdd?.supplier?.code == 'FRS644' || productsSupplierToAdd?.supplier?.code == 'FRS643'|| productsSupplierToAdd?.supplier?.code  == 'FRS631'" class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="nc8">Code douanier NC8*</label>
                                                                        <div class="mb-2">
                                                                            <input required type="text" class="form-control"
                                                                                    id="nc8" name="nc8"
                                                                                    [(ngModel)]="productsSupplierToAdd.nc8"
                                                                                    #nc8="ngModel"/>
                                                                            <em class="error help-block"
                                                                                *ngIf="f.invalid && nc8.errors?.required">
                                                                                Merci de saisir un code nc8
                                                                            </em>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="productsSupplierToAdd?.supplier?.code == 'FRS729' || productsSupplierToAdd?.supplier?.code == 'FRS644' || productsSupplierToAdd?.supplier?.code == 'FRS643'|| productsSupplierToAdd?.supplier?.code  == 'FRS631'" class="col-md-4 mb-3">
                                                                        <label class="control-label" for="weight">Poids nette produit kg*</label>
                                                                        <div class="mb-2">
                                                                            <input required type="number" class="form-control"
                                                                                   id="weight" name="weight"
                                                                                   [(ngModel)]="productsSupplierToAdd.weight"
                                                                                   #weight="ngModel"/>
                                                                            <em class="error help-block"
                                                                                *ngIf="f.invalid && weight.errors?.required">
                                                                                Merci de saisir un poids
                                                                            </em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3"
                                                                         *ngIf="allDeliveryCountries.length > 0">
                                                                        <label class="control-label"
                                                                               for="deliveryCountries">Autre(s) Pays de
                                                                            livraison</label>
                                                                        <ng-multiselect-dropdown id="deliveryCountries"
                                                                                                 name="deliveryCountries"
                                                                                                 [settings]="dropdownSettingsDeliveryCountries"
                                                                                                 [(ngModel)]="selectedDeliveryCountries"
                                                                                                 [placeholder]="'Sélectionner autre(s) pays du fournisseur'"
                                                                                                 [data]="allDeliveryCountries">

                                                                            <ng-template #optionsTemplate
                                                                                         let-option="option">
                                                                                <div>
                                                                                    <img [src]="'assets/images/flags/' + option + '.png'"
                                                                                         style="width: 18px; margin-right: 2px;"/>
                                                                                    {{option}}
                                                                                </div>
                                                                            </ng-template>
                                                                        </ng-multiselect-dropdown>
                                                                    </div>
                                                                    <div class="col-md-12 mb-3">
                                                                        <ngb-tabset style="text-align: left;" class="tab round shadow">
                                                                            <ngb-tab
                                                                                    *ngFor="let translation of productsSupplierToAdd.translationProductSupplier">
                                                                                <ng-template ngbTabTitle>{{translation.language.code}}
                                                                                </ng-template>
                                                                                <ng-template ngbTabContent>
                                                                                    <label class="control-label">Libellé fournisseur*</label>
                                                                                    <input #translationLabel="ngModel"
                                                                                           id="translationLabel"
                                                                                           [(ngModel)]="translation.label"
                                                                                           (blur)="updateEmptyLibilleSupplierLg(translation.label)"
                                                                                           type="text" required
                                                                                           class="form-control"
                                                                                           name="translationLabel"/>
                                                                                </ng-template>
                                                                            </ngb-tab>
                                                                        </ngb-tabset>
                                                                    </div>
                                                                    <div class="col-md-12 mb-3">
                                                                        <div class="card card-statistics mb-30">
                                                                            <div class="card-body">
                                                                                <h5 class="card-title">Prix d'achat</h5>
                                                                                <a (click)="addBuyingPrice()"
                                                                                   href="javascript:void(0);"
                                                                                   class="add-price"
                                                                                   data-toggle="tooltip"
                                                                                   data-placement="left" title=""
                                                                                   ngbTooltip="Ajouter un prix d'achat">
                                                                                   
                                                                                   <i style="width: 40px;font-size: 26px;" class="fa fa-plus text-success action-button"></i>

                                                                                
                                                                                </a>

                                                                                <div *ngFor="let productSupplierPrice of productsSupplierToAdd?.productSupplierPrices; let index = index;"
                                                                                     class="card card-statistics mb-30">
                                                                                    <div class="card-body">
                                                                                        <a *ngIf="!productSupplierPrice.idProductSupplierPrice"
                                                                                           (click)="deleteBuyingPrice(index)"
                                                                                           class="delete-price"
                                                                                           data-toggle="tooltip"
                                                                                           data-placement="left"
                                                                                           title=""
                                                                                           ngbTooltip="Supprimer le prix d'achat">
                                                                                            <img style="width: 30px;"
                                                                                                 src="assets/images/delete.png">
                                                                                        </a>
                                                                                        <div style="padding-top:10px"
                                                                                             class="form-row">
                                                                                            <div class="col-md-3 mb-3">
                                                                                                <label
                                                                                                        class="control-label"
                                                                                                        for="dutyFreeBuyingPrice_{{index}}">Prix
                                                                                                    d'achat HT*</label>
                                                                                                <div class="mb-2">
                                                                                                    <input
                                                                                                            (keyup)="calculBuyingPrices(productSupplierPrice)"
                                                                                                            type="number"
                                                                                                            required
                                                                                                            class="form-control"
                                                                                                            #dutyFreeBuyingPrice="ngModel"
                                                                                                            id="dutyFreeBuyingPrice_{{index}}"
                                                                                                            name="dutyFreeBuyingPrice_{{index}}"
                                                                                                            [(ngModel)]="productSupplierPrice.dutyFreeBuyingPrice"/>
                                                                                                    <em class="error help-block"
                                                                                                        *ngIf="f.invalid && dutyFreeBuyingPrice.errors?.required">Merci
                                                                                                        de
                                                                                                        saisir un Prix
                                                                                                        d'achat HT</em>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-3 mb-3">
                                                                                                <label
                                                                                                        class="control-label"
                                                                                                        for="allTaxesIncludedBuyingPrice_{{index}}">Prix
                                                                                                    d'achat TTC*</label>
                                                                                                <div class="mb-2">
                                                                                                    <input type="number"
                                                                                                           required
                                                                                                           disabled
                                                                                                           class="form-control"
                                                                                                           id="allTaxesIncludedBuyingPrice_{{index}}"
                                                                                                           name="allTaxesIncludedBuyingPrice_{{index}}"
                                                                                                           [(ngModel)]="productSupplierPrice.allTaxesIncludedBuyingPrice"/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-3 mb-3">
                                                                                                <label
                                                                                                        class="control-label"
                                                                                                        for="vat_{{index}}">Taux
                                                                                                    TVA*</label>
                                                                                                <div class="mb-2">
                                                                                                    <select
                                                                                                            id="vat_{{index}}"
                                                                                                            [disabled]="disableVat"
                                                                                                            (change)="calculBuyingPrices(productSupplierPrice)"
                                                                                                            required
                                                                                                            [compareWith]="compareFn"
                                                                                                            #vat="ngModel"
                                                                                                            name="vat_{{index}}"
                                                                                                            [(ngModel)]="productSupplierPrice.vat"
                                                                                                            class="custom-select custom-select-lg mb-3">
                                                                                                        <option
                                                                                                                *ngFor="let vat of vats"
                                                                                                                [ngValue]="vat"
                                                                                                                selected>
                                                                                                            {{vat.rate}}
                                                                                                        </option>
                                                                                                    </select>
                                                                                                    <em class="error help-block"
                                                                                                        *ngIf="f.invalid && vat.errors?.required">Merci
                                                                                                        de saisir un
                                                                                                        Taux
                                                                                                        TVA</em>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-3 mb-3">
                                                                                                <label
                                                                                                        class="control-label"
                                                                                                        for="vatAmount_{{index}}">Montant
                                                                                                    TVA*</label>
                                                                                                <div class="mb-2">
                                                                                                    <input type="number"
                                                                                                           required
                                                                                                           disabled
                                                                                                           class="form-control"
                                                                                                           id="vatAmount_{{index}}"
                                                                                                           name="vatAmount_{{index}}"
                                                                                                           [(ngModel)]="productSupplierPrice.vatAmount"/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="dutyFreeBuyingPriceToAdd">Prix
                                                                            d'achat total HT*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="dutyFreeBuyingPriceToAdd"
                                                                                   name="dutyFreeBuyingPriceToAdd"
                                                                                   [(ngModel)]="productsSupplierToAdd.dutyFreeBuyingPrice"
                                                                                   #dutyFreeBuyingPriceToAdd="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="allTaxesIncludedBuyingPriceToAdd">Prix
                                                                            d'achat total TTC*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="allTaxesIncludedBuyingPriceToAdd"
                                                                                   name="allTaxesIncludedBuyingPriceToAdd"
                                                                                   [(ngModel)]="productsSupplierToAdd.allTaxesIncludedBuyingPrice"
                                                                                   #allTaxesIncludedBuyingPriceToAdd="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="vatAmountToAdd">Montant
                                                                            TVA
                                                                            total*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="vatAmountToAdd"
                                                                                   name="vatAmountToAdd"
                                                                                   [(ngModel)]="productsSupplierToAdd.vatAmount"
                                                                                   #vatAmountToAdd="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="dutyFreeShippingPrice">Frais
                                                                            de port HT*</label>
                                                                        <div class="mb-2">
                                                                            <input
                                                                                    (keyup)="calculAllTaxesIncludedShippingPrice()"
                                                                                    type="number" required
                                                                                    class="form-control"
                                                                                    id="dutyFreeShippingPrice"
                                                                                    name="dutyFreeShippingPrice"
                                                                                    [(ngModel)]="productsSupplierToAdd.dutyFreeShippingPrice"
                                                                                    #dutyFreeShippingPrice="ngModel"/>
                                                                            <em class="error help-block"
                                                                                *ngIf="f.invalid && dutyFreeShippingPrice.errors?.required">Merci
                                                                                de saisir les Frais de
                                                                                port HT</em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="allTaxesIncludedShippingPrice">Frais
                                                                            de port TTC*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="allTaxesIncludedShippingPrice"
                                                                                   name="allTaxesIncludedShippingPrice"
                                                                                   [(ngModel)]="productsSupplierToAdd.allTaxesIncludedShippingPrice"
                                                                                   #allTaxesIncludedShippingPrice="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="allTaxesIncludedPublicPrice">Prix
                                                                            public fournisseur</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" class="form-control"
                                                                                   id="allTaxesIncludedPublicPrice"
                                                                                   name="allTaxesIncludedPublicPrice"
                                                                                   [(ngModel)]="productsSupplierToAdd.allTaxesIncludedPublicPrice"
                                                                                   #allTaxesIncludedPublicPrice="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label" for="ecoTaxes">Ecos
                                                                            taxes</label>
                                                                        <div class="mb-2">
                                                                            <input (keyup)="calculAllTotalBuyingPrice()"
                                                                                   type="number" class="form-control"
                                                                                   id="ecoTaxes" name="ecoTaxes"
                                                                                   [(ngModel)]="productsSupplierToAdd.ecoTaxes"
                                                                                   #ecoTaxes="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="d3e">D3e</label>
                                                                        <div class="mb-2">
                                                                            <input (keyup)="calculAllTotalBuyingPrice()"
                                                                                   type="number" class="form-control"
                                                                                   id="d3e" name="d3e"
                                                                                   [(ngModel)]="productsSupplierToAdd.d3e"
                                                                                   #d3e="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="sorecop">Sorecop</label>
                                                                        <div class="mb-2">
                                                                            <input (keyup)="calculAllTotalBuyingPrice()"
                                                                                   type="number" class="form-control"
                                                                                   id="sorecop" name="sorecop"
                                                                                   [(ngModel)]="productsSupplierToAdd.sorecop"
                                                                                   #sorecop="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="dutyFreeTotalBuyingPrice">Prix
                                                                            de revient HT*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="dutyFreeTotalBuyingPrice"
                                                                                   name="dutyFreeTotalBuyingPrice"
                                                                                   [(ngModel)]="productsSupplierToAdd.dutyFreeTotalBuyingPrice"
                                                                                   #dutyFreeTotalBuyingPrice="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="allTaxesIncludedTotalBuyingPrice">Prix
                                                                            de revient TTC*</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control"
                                                                                   id="allTaxesIncludedTotalBuyingPrice"
                                                                                   name="allTaxesIncludedTotalBuyingPrice"
                                                                                   [(ngModel)]="productsSupplierToAdd.allTaxesIncludedTotalBuyingPrice"
                                                                                   #allTaxesIncludedTotalBuyingPrice="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 mb-3">
                                                                        <label class="control-label"
                                                                               for="margin">Marge</label>
                                                                        <div class="mb-2">
                                                                            <input type="number" required disabled
                                                                                   class="form-control" id="margin"
                                                                                   name="margin"
                                                                                   [(ngModel)]="productsSupplierToAdd.margin"
                                                                                   #margin="ngModel"/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </ng-template>
                                                        </ngb-tab>
                                                        <ngb-tab id="sizes"
                                                                 *ngIf="productsSupplierToAdd.childProductSuppliers.length > 0"
                                                                 title="Tailles">
                                                            <ng-template ngbTabContent>
                                                                <label *ngIf="dataMissing" style="color:red"
                                                                       class="control-label">*Merci
                                                                    de remplir toutes les données obligatoires du
                                                                    tableau</label>

                                                                <div class="card card-statistics h-100">
                                                                    <div class="card-body">

                                                                        <div class="table-responsive">
                                                                            <ngx-datatable #sizesDatatable
                                                                                           class="bootstrap"
                                                                                           [headerHeight]="50"
                                                                                           [limit]="10"
                                                                                           [columnMode]="'force'"
                                                                                           [footerHeight]="50"
                                                                                           [rowHeight]="'auto'"
                                                                                           [rows]="productsSupplierToAdd.childProductSuppliers">

                                                                                <ngx-datatable-row-detail
                                                                                        [rowHeight]="570" #myDetailRow
                                                                                        (toggle)="onDetailToggle($event)">
                                                                                    <ng-template let-rowIndex="rowIndex"
                                                                                                 let-row="row"
                                                                                                 let-expanded="expanded"
                                                                                                 ngx-datatable-row-detail-template>
                                                                                        <div
                                                                                                class="card card-statistics mb-30">
                                                                                            <div class="card-body">
                                                                                                <div class="form-row">
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_code">Code*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    name="size_code"
                                                                                                                    id="size_code"
                                                                                                                    [value]="row.code != null ? row.code : null"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_size">Taille*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    name="size_size"
                                                                                                                    id="size_size"
                                                                                                                    [value]="row.size != null ? row.size : null"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_stock">Stock*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    name="size_stock"
                                                                                                                    id="size_stock"
                                                                                                                    [value]="row.stock != null ? row.stock : null"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_dutyFreeBuyingPrice">Prix
                                                                                                            d'achat
                                                                                                            HT*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    name="size_dutyFreeBuyingPrice"
                                                                                                                    id="size_dutyFreeBuyingPrice"
                                                                                                                    [value]="row.dutyFreeBuyingPrice"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_allTaxesIncludedBuyingPrice-{{rowIndex}}">Prix
                                                                                                            d'achat
                                                                                                            TTC*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    disabled
                                                                                                                    class="form-control"
                                                                                                                    id="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                                    name="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                                    [value]="row.allTaxesIncludedBuyingPrice != null ? row.allTaxesIncludedBuyingPrice : null"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_allTaxesIncludedBuyingPrice-{{rowIndex}}">Taux
                                                                                                            TVA*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    disabled
                                                                                                                    class="form-control"
                                                                                                                    id="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                                    name="size_allTaxesIncludedBuyingPrice-{{rowIndex}}"
                                                                                                                    [value]="row.productSupplierPrices != null && row.productSupplierPrices.length != 0 ? row.productSupplierPrices[0]?.vat?.rate : 0"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_dutyFreeShippingPrice">Frais
                                                                                                            de
                                                                                                            port
                                                                                                            HT</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    id="size_dutyFreeShippingPrice"
                                                                                                                    name="size_dutyFreeShippingPrice"
                                                                                                                    [value]="row.dutyFreeShippingPrice"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_allTaxesIncludedShippingPrice">Frais
                                                                                                            de port
                                                                                                            TTC</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    id="size_allTaxesIncludedShippingPrice"
                                                                                                                    name="size_allTaxesIncludedShippingPrice"
                                                                                                                    [value]="row.allTaxesIncludedShippingPrice"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_vatAmount">Montant
                                                                                                            TVA*</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    id="size_vatAmount"
                                                                                                                    name="size_vatAmount"
                                                                                                                    [value]="row.vatAmount"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="siz_ecoTaxes">Ecos
                                                                                                            taxes</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    class="form-control"
                                                                                                                    id="siz_ecoTaxes"
                                                                                                                    name="siz_ecoTaxes"
                                                                                                                    [value]="row.ecoTaxes"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_d3e">D3e</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    class="form-control"
                                                                                                                    id="size_d3e"
                                                                                                                    name="size_d3e"
                                                                                                                    [value]="row.d3e"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_sorecop">Sorecop</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    class="form-control"
                                                                                                                    id="size_sorecop"
                                                                                                                    name="size_sorecop"
                                                                                                                    [value]="row.sorecop"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_dutyFreeTotalBuyingPrice">Prix
                                                                                                            de revient
                                                                                                            HT</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    id="size_dutyFreeTotalBuyingPrice"
                                                                                                                    name="size_dutyFreeTotalBuyingPrice"
                                                                                                                    [value]="row.dutyFreeTotalBuyingPrice"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label"
                                                                                                                for="size_allTaxesIncludedTotalBuyingPrice">Prix
                                                                                                            de revient
                                                                                                            TTC</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    disabled
                                                                                                                    type="number"
                                                                                                                    required
                                                                                                                    class="form-control"
                                                                                                                    id="size_allTaxesIncludedTotalBuyingPrice"
                                                                                                                    name="size_allTaxesIncludedTotalBuyingPrice"
                                                                                                                    [value]="row.allTaxesIncludedTotalBuyingPrice"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label">Marge</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <input
                                                                                                                    type="number"
                                                                                                                    disabled
                                                                                                                    class="form-control"
                                                                                                                    id="size_margin"
                                                                                                                    name="size_margin"
                                                                                                                    [value]="row.margin"
                                                                                                                    (keyup)="updateRow(row,$event)"/>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                            class="col-md-4 mb-3">
                                                                                                        <label
                                                                                                                class="control-label">Statut</label>
                                                                                                        <div
                                                                                                                class="mb-2">
                                                                                                            <select
                                                                                                                    class="custom-select custom-select-lg mb-3">
                                                                                                                <option
                                                                                                                        value="published">
                                                                                                                    Publié
                                                                                                                </option>
                                                                                                                <option
                                                                                                                        value="unpublished">
                                                                                                                    Dépublié
                                                                                                                </option>
                                                                                                                <option
                                                                                                                        value="standby">
                                                                                                                    Stand-by
                                                                                                                </option>
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ngx-datatable-row-detail>

                                                                                <ngx-datatable-column name="">

                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span
                                                                                                (click)="toggleExpandRow(row)"
                                                                                                style="color: #1212ef;text-decoration: underline;cursor: pointer;">Editer</span>
                                                                                    </ng-template>

                                                                                </ngx-datatable-column>

                                                                                <ngx-datatable-column name="code">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Code</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>

                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column name="size">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Taille</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column name="stock">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Stock</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column
                                                                                        name="dutyFreeBuyingPrice">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Prix HT</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column
                                                                                        name="dutyFreeShippingPrice">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>FP HT</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>

                                                                                <ngx-datatable-column name="vatAmount">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Montant TVA</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column
                                                                                        name="dutyFreeTotalBuyingPrice">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>PR HT</span>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                            ngx-datatable-cell-template
                                                                                            let-rowIndex="rowIndex"
                                                                                            let-value="value"
                                                                                            let-row="row">
                                                                                        <span>{{value}}</span>
                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                                <ngx-datatable-column name="state">
                                                                                    <ng-template let-column="column"
                                                                                                 ngx-datatable-header-template>
                                                                                        <span>Statut</span>
                                                                                    </ng-template>
                                                                                    <ng-template let-row="row"
                                                                                                 ngx-datatable-cell-template
                                                                                                 let-value="value">
                                                                                        <span
                                                                                                [ngClass]="{'badge-danger': value==='declined', 'badge-warning': value==='unpublished','badge-primary': value==='standby',  'badge-success': value==='published', 'badge-info': value==='selected', 'badge-secondary':value==='new'}"
                                                                                                class="badge badge_taille">{{'core.status.'
                                                                                        +
                                                                                        value | translate}}</span>

                                                                                    </ng-template>
                                                                                </ngx-datatable-column>
                                                                            </ngx-datatable>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-tab>
                                                    </ngb-tabset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                                            (click)="closeModal('productsSupplierToAdd')">
                                        Fermer
                                    </button>
                                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                                            (click)="addSupplier(f.form.valid)">
                                        Ajouter
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </app-model>
            </div>
        </div>
    </div>

    <div class="card card-statistics mb-30">
        <div class="card-body">
            <h5 class="card-title">Prix de vente</h5>
            <img class="flag-deliveryCountry" src="assets/images/countries/{{deliveryCountry.code}}.jpg" alt="Drapeau_{{deliveryCountry.code}}">

            <div class="form-row">
                <span (click)="pricesEnseignes()" class="text-primary message-enseignes-prices">Voir détails
                    prix
                    de vente par enseigne</span>
                <div class="col-md-4 mb-3">
                    <label class="control-label" for="dutyFreeSalePrice">Prix de vente HT*</label>
                    <div class="mb-2">
                        <input (keyup)="changeSalePrice(catalogue)" type="number" class="form-control"
                               id="dutyFreeSalePrice" name="dutyFreeSalePrice" [(ngModel)]="catalogue.dutyFreeSalePrice"
                               #dutyFreeSalePrice="ngModel"/>
                        <em class="error help-block" *ngIf="validationData.dutyFreeSalePrice">Merci de saisir un prix
                            de vente HT</em>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label class="control-label" for="allTaxesIncludedSalePrice">Prix de vente TTC</label>
                    <div class="mb-2">
                        <input disabled type="number" class="form-control" id="allTaxesIncludedSalePrice"
                               name="allTaxesIncludedSalePrice" [(ngModel)]="catalogue.allTaxesIncludedSalePrice"
                               #allTaxesIncludedSalePrice="ngModel"/>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label class="control-label" for="margin">Marge</label>
                    <div class="mb-2">
                        <input disabled type="number" class="form-control" id="margin" name="margin"
                               [(ngModel)]="catalogue.margin" #margin="ngModel"/>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label class="control-label" for="allTaxesIncludedPublicPrice">Prix publique TTC</label>
                    <div class="mb-2">
                        <input type="number" class="form-control" id="allTaxesIncludedPublicPrice"
                               name="allTaxesIncludedPublicPrice" [(ngModel)]="catalogue.allTaxesIncludedPublicPrice"
                               #allTaxesIncludedPublicPrice="ngModel"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-4 mb-3">
            <label class="control-label" for="range">
                Paliers*
            </label>
            <div class="mb-2">
                <select required #range="ngModel" [(ngModel)]="productYoukado.range" (change)="onChangeRange()"
                    [compareWith]="compareFn" name="range" id="range" class="custom-select custom-select-lg mb-3">
                    <option value=""></option>
                    <option *ngFor="let range of ranges" [ngValue]="range">{{range.code}} {{range.dutyFreeSalePrice}}
                        HT {{range.margin}}%
                    </option>
                </select>
                <em class="error help-block" *ngIf="validationData.ranges">Merci de choisir un palier</em>
            </div>
        </div> -->
    </div>

</div>